import { useLocation } from 'react-router-dom'
import { paths } from './paths'
import { AppRoute } from '../routes'

export const projectSections = [
  'device-settings',
  'device',
  'blueprint',
  'tag',
  'logs',
  'stream',
  'artifact',
  'release',
  'settings',
  'pipeline',
  'apikeys',
  'certificates',
  'secrets',
] as const

export type ProjectSection = typeof projectSections[number]

/** Get the top level section of the current page */
export function useProjectSection():
  | typeof projectSections[number]
  | undefined {
  const { pathname } = useLocation()

  // Shave off any sub paths beyond the child page of the main context:
  const projectPrefix = pathname.match(/^\/org\/[\w-]+\/project\/[\w-]+/)

  if (projectPrefix) {
    const subPath =
      pathname
        .slice(projectPrefix[0].length + 1)
        .split('/', 1)
        .pop() ?? 'device'
    return projectSections.find(k => subPath.includes(k))
  }
}

/** Get the index route of the current section */
export function useProjectSectionIndex() {
  const indexMap: Record<ProjectSection, AppRoute> = {
    apikeys: paths.projects.apiKeys,
    artifact: paths.projects.artifacts.index,
    blueprint: paths.projects.blueprints.index,
    certificates: paths.projects.certificates,
    device: paths.projects.devices.index,
    'device-settings': paths.projects.deviceSettings,
    logs: paths.projects.logs,
    pipeline: paths.projects.pipelines.index,
    release: paths.projects.releases.index,
    secrets: paths.projects.secrets,
    settings: paths.projects.settings,
    stream: paths.projects.stream,
    tag: paths.projects.tags,
  }

  const section = useProjectSection()
  return section ? indexMap[section] : undefined
}
