import { autorun } from 'mobx'
import { useStore } from './useStore'
import { useEffect, useState } from 'react'
import { Project } from '../interfaces/IProject'

export interface OrganizationTreeOrg {
  id: string
  admin: boolean
  projects: Project[]
}

/**
 * A hook to get all organizations and their projects.
 * This includes all organizations where the user has access
 * to one or more projects.
 *
 * @returns The organization tree or undefined if the org tree is still loading.
 */
export function useOrgTree() {
  const { organizations } = useStore('organization')
  const { selectProjects } = useStore('project')
  const [orgTree, setOrgTree] = useState<OrganizationTreeOrg[] | undefined>(
    undefined,
  )

  /* The autorun function takes care of the reactivity of the hook,
   * but we still have to wrap it in a useEffect to avoid recreating
   * the autorun function on every render.
   *
   * autorun returns a disposer function that will be called when the
   * component is unmounted.
   */
  useEffect(
    () =>
      autorun(() => {
        const projects = selectProjects.current()
        const orgs = organizations.current()
        if (!projects || !orgs) {
          return undefined
        }

        const tree = projects.reduce(
          (reduced: OrganizationTreeOrg[], p) => {
            const existing = reduced.find(o => o.id === p.organizationId)
            if (existing) {
              existing.projects.push(p)
              return reduced
            }
            reduced.push({
              id: p.organizationId,
              admin: false,
              projects: [p],
            })
            return reduced
          },
          orgs.map(o => {
            return {
              id: o.id,
              admin: true,
              projects: [],
            } as OrganizationTreeOrg
          }) ?? [],
        )

        setOrgTree(tree)
      }),
    [],
  )

  return orgTree
}
