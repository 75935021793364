/* eslint-disable @typescript-eslint/no-explicit-any */

const loadCannySDK = (w: any, d: Document, i: string, s: string) => {
  function l() {
    if (!d.getElementById(i)) {
      const f = d.getElementsByTagName(s)[0] as any
      const e = d.createElement(s) as any
      e.type = 'text/javascript'
      e.async = true
      e.src = 'https://canny.io/sdk.js'
      f.parentNode.insertBefore(e, f)
    }
  }
  if ('function' != typeof w.Canny) {
    const c = function (...props: any[]) {
      c.q.push(props)
    } as any
    c.q = []
    w.Canny = c
    if ('complete' === d.readyState) {
      l()
    } else {
      if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  }
}

export const cannySDK = () => {
  if (window.Canny) return window.Canny
  loadCannySDK(window, document, 'canny-jssdk', 'script')
  return window.Canny
}
