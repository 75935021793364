import { ListResponse, Response } from '../../interfaces/IApiResponses'
import { ProjectContext } from '../../interfaces/IProject'
import { Secret, SecretCreate, SecretType } from '../../interfaces/ISecret'
import { serverClient } from '../apiClients'

function url({ org, project }: ProjectContext, subPath?: string) {
  const base = `/organizations/${org}/projects/${project}/secrets`
  return subPath ? `${base}/${subPath}` : base
}

export async function fetchSecrets(ctx: ProjectContext, type?: SecretType) {
  const response = await serverClient.get<ListResponse<Secret>>(url(ctx), {
    params: {
      type,
    },
  })

  return response.data
}

export async function fetchSecret(ctx: ProjectContext, name: string) {
  const response = await serverClient.get<Response<Secret>>(url(ctx, name))

  return response.data.data
}

export async function createSecret(ctx: ProjectContext, secret: SecretCreate) {
  const response = await serverClient.post<Response<Secret>>(url(ctx), secret)

  return response.data.data
}

export async function deleteSecret(ctx: ProjectContext, name: string) {
  await serverClient.delete(url(ctx, name))
}
