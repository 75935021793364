import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import {
  Blueprint,
  BlueprintData,
  BlueprintFilters,
} from '../../interfaces/IBlueprint'
import { ProjectContext } from '../../interfaces/IProject'
import { serverClient } from '../apiClients'

function url({ project }: ProjectContext, subPath?: string) {
  const base = `/projects/${project}/blueprints`

  return subPath ? `${base}/${subPath}` : base
}

export async function fetchBlueprints(
  ctx: ProjectContext,
  page?: number,
  perPage?: number,
  filters?: BlueprintFilters,
) {
  const response = await serverClient.get<PaginatedListResponse<Blueprint>>(
    url(ctx),
    {
      params: { page, perPage, ...filters },
    },
  )

  return response.data
}

export async function fetchBlueprint(ctx: ProjectContext, id: string) {
  const response = await serverClient.get<Response<Blueprint>>(url(ctx, id))

  return response.data.data
}

export async function storeBlueprint(ctx: ProjectContext, data: BlueprintData) {
  if (data.id) {
    const response = await serverClient.put<Response<Blueprint>>(
      url(ctx, data.id),
      data,
    )

    return response.data.data
  } else {
    const response = await serverClient.post<Response<Blueprint>>(
      url(ctx),
      data,
    )

    return response.data.data
  }
}

export async function deleteBlueprint(ctx: ProjectContext, id: string) {
  await serverClient.delete(url(ctx, id))
}
