import { Button, Row, Space, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { EchoAnimation } from './EchoAnimation'
import { VerticalSpace } from '../components/custom-antd/VerticalSpace'
import { SplashScreenBackground } from './layout/SplashScreenBackground'
import { FullHeightCenterScreen } from './styled/FullHeightCenterScreen'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const { Text } = Typography

interface EchoResultProps {
  title: string
  message: string
  button?: {
    label: string
    target: string
  }
}

export function EchoResult({ title, message, button }: EchoResultProps) {
  const { goBack } = useHistory()
  const { md } = useBreakpoint()

  return (
    <SplashScreenBackground>
      <FullHeightCenterScreen>
        <VerticalSpace size='large' style={{ fontSize: 24 }}>
          <Row
            justify='center'
            align='middle'
            style={md ? { paddingLeft: 100 } : { textAlign: 'center' }}
            wrap={!md}
          >
            <EchoAnimation />
            <Space direction='vertical' style={{ width: 370 }}>
              <Text style={{ fontSize: 48, lineHeight: '1.2em' }}>{title}</Text>
              <Text type='secondary'>{message}</Text>
            </Space>
          </Row>
          <Row justify='center'>
            {button ? (
              <Button size='large' type='primary' href={button.target}>
                {button.label}
              </Button>
            ) : (
              <Button size='large' type='primary' onClick={() => goBack()}>
                Go Back
              </Button>
            )}
          </Row>
        </VerticalSpace>
      </FullHeightCenterScreen>
    </SplashScreenBackground>
  )
}
