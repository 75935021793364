import { Divider, Modal, SpaceProps } from 'antd'
import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { VerticalSpace } from '../custom-antd/VerticalSpace'
import logo from '../../assets/images/g-logo.png'
import nameLogo from '../../assets/images/name-logo-dark.svg'
import { SplashScreenBackground } from './SplashScreenBackground'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export const SplashScreen: FunctionComponent<SpaceProps> = ({
  children,
  ...props
}) => {
  const { md } = useBreakpoint()
  return (
    <SplashScreenBackground>
      <div style={{ position: 'relative', maxWidth: md ? '60%' : '100%' }}>
        <Modal
          open
          className='splash-screen'
          centered={md}
          closable={false}
          footer={null}
          mask={false}
          maskClosable={false}
          zIndex={0}
        >
          <VerticalSpace style={{ textAlign: 'center' }}>
            <img width='110' src={logo} />
            <img width='80' src={nameLogo} />
          </VerticalSpace>
          <Divider />
          <Content {...props}>{children}</Content>
        </Modal>
      </div>
    </SplashScreenBackground>
  )
}

const Content = styled(VerticalSpace)`
  text-align: center;

  h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 2.2em;
    margin-bottom: 0;
  }
`
