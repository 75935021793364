import { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import { AccessPolice, ResolvedRoute, routes } from '../../routes'
import PrivateRoute from './PrivateRoute'

interface SwitchRoutesProps {
  routeProps: Record<string, ResolvedRoute>
}
/** Wrapper around Route and PrivateRoute, selecting one based on accessPolice */
const AppRoute = ({
  accessPolice,
  ...otherProps
}: ResolvedRoute & { path: string }) => {
  const RouteComponent =
    accessPolice === AccessPolice.Auth ? PrivateRoute : Route

  return <RouteComponent {...otherProps} />
}

const SwitchRoutes: FunctionComponent<SwitchRoutesProps> = ({ routeProps }) => {
  const mappedRoutes = createRoutes(routeProps)

  return <Switch>{mappedRoutes}</Switch>
}

/**
 * Function that maps our routes and its children.
 *
 * Returns a list of <Route> components.
 * Routes that have a layout will be wrapped in the layout component,
 * and its children will be wrapped in a nested route switch, next to the
 * route itself, like this:
 *
 * ```
 * <Route path="/path"> <- Owns all /path/* routes
 *  <Layout> <- Defined by the route
 *    <Switch>
 *      <Route path="/path" exact /> <- The actual route!
 *      <Route path="/path/child1" />
 *      <Route path="/path/child2" />
 *    </Switch>
 *  </Layout>
 * </Route>
 * ```
 * This follows the nesting example: https://v5.reactrouter.com/web/example/nesting
 *
 * Pages that don't have a layout will just be added to the list of routes without
 * nesting.
 */
const createRoutes = (
  routeProps: Record<string, ResolvedRoute>,
  parentPath?: string,
) => {
  const routes: React.ReactElement[] = []

  Object.entries(routeProps).map(([route, props]) => {
    const { routes: childRoutes, layout: Layout, ...otherProps } = props
    const path = parentPath ? parentPath + route : route

    const children = childRoutes && createRoutes(childRoutes, path)

    if (Layout) {
      routes.push(
        <Route path={path} key={path}>
          <Layout>
            <Switch>
              <AppRoute key={path} {...otherProps} exact path={path} />
              {children}
            </Switch>
          </Layout>
        </Route>,
      )
    } else {
      routes.push(
        <AppRoute key={path} exact={!!children} {...otherProps} path={path} />,
      )
      if (children) {
        routes.push(...children)
      }
    }
  })

  return routes
}

export const Routes = () => <SwitchRoutes routeProps={routes} />
