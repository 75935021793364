import axios from 'axios'
import jsonexport from 'jsonexport'
import { action, autorun, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import {
  Device,
  DeviceData,
  DeviceFilters,
  DeviceLightDBData,
} from '../interfaces/IDevice'
import { ProvisionData } from '../interfaces/IProvision'
import { randomName } from '../shared/randomName'
import {
  deleteDevice,
  deleteDeviceDataNode,
  fetchDevice,
  fetchDeviceData,
  fetchDevices,
  patchDevice,
  provisionDeviceAndCredentials,
  storeDevice,
  storeDeviceData,
} from '../shared/serverApi/deviceApi'
import { ProjectContextStore } from './ProjectContextStore'
import { AsyncCache } from './AsyncCache'
import { Paginated } from './Paginated'

export class DeviceStore extends ProjectContextStore {
  @observable isLoading = false
  @observable isSelectLoading = false
  @observable isExporting = false
  @observable isLoadingData = false
  @observable isRemoving = false
  // Bulk
  @observable isBulkRemoving = false
  @observable isBulkTogglingEnable = false
  @observable isBulkTagging = false

  @observable deviceListFilters: DeviceFilters = {
    term: undefined,
    enabled: true,
  }

  @observable device: Device | null = null
  @observable devices = new Paginated<Device, DeviceFilters>(
    request =>
      fetchDevices(
        this.context,
        request.page,
        request.perPage,
        request.filters,
      ),
    { enabled: true },
    { canChangePageSize: true },
  )
  @observable deviceData: DeviceLightDBData = {}
  @observable selectDevices: Device[] = []

  @observable selectDevicesTotal = 0

  @observable deviceCache = new AsyncCache(
    id => fetchDevice(this.context, id),
    d => ({ name: d.name, tags: d.tagIds }),
  )

  constructor() {
    super('devices')
    makeObservable(this)

    autorun(() => this.deviceCache.refresh(this.device))
    autorun(() => this.deviceCache.refresh(this.devices))
    autorun(() => this.deviceCache.refresh(this.selectDevices))
  }

  @action.bound
  protected onContextChange(): void | Promise<void> {
    this.device = null
    this.devices.reset()
    this.deviceData = {}
    this.selectDevices = []
  }

  @action.bound
  setDeviceListFilters(filters: DeviceFilters) {
    this.deviceListFilters = filters
  }

  @action.bound
  setDeviceData(deviceData: DeviceLightDBData) {
    this.deviceData = { ...deviceData }
  }

  @action.bound
  async loadDevice(id: string, silentCatch?: boolean) {
    try {
      this.isLoading = true
      const device = await fetchDevice(this.context, id)

      this.device = device
      return device
    } catch (e) {
      if (!silentCatch) DefaultErrorNotification(e)
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveDevice(data: DeviceData) {
    try {
      this.isLoading = true
      const device = await storeDevice(this.context, data)
      const action = data.id ? 'updated' : 'created'

      this.device = device

      DefaultSuccessMessage('Device', action, {
        name: data.name,
      })
      return device
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async provisionDevice(data: ProvisionData) {
    try {
      this.isLoading = true
      const deviceId = await provisionDeviceAndCredentials(this.context, data)

      DefaultSuccessMessage('Device', 'created', {
        name: data.name,
      })
      return deviceId
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async provisionMultipleDevice() {
    try {
      this.isLoading = true

      const devicesData = new Array(50).fill(0).map(() => ({
        name: `${randomName()}`,
      }))

      await axios.all(devicesData.map(data => storeDevice(this.context, data)))

      DefaultSuccessMessage('50 Device', 'created')
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeDevice(id: string) {
    try {
      this.isRemoving = true
      await deleteDevice(this.context, id)

      if (this.device?.id === id) {
        this.device = null
      }

      DefaultSuccessMessage('Device', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isRemoving = false
    }
  }

  @action.bound
  async bulkRemoveDevices(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteDevice(this.context, id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Device(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async bulkToggleEnableDevices(ids: string[], enabled: boolean) {
    try {
      this.isBulkTogglingEnable = true
      const result = await Promise.allSettled(
        ids.map(id => patchDevice(this.context, id, { enabled })),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Device(s)`, 'updated')
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isBulkTogglingEnable = false
    }
  }

  @action.bound
  async bulkTagDevices(ids: string[], tags: string[]) {
    try {
      this.isBulkTagging = true
      const result = await Promise.allSettled(
        ids.map(id => patchDevice(this.context, id, { tagIds: tags })),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Device(s)`, 'updated')
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isBulkTagging = false
    }
  }

  @action.bound
  async exportDevices() {
    try {
      this.isExporting = true
      const response = await fetchDevices(this.context)
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'devices.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }

  @action.bound
  async loadSelectDevices(
    page?: number,
    pageSize?: number,
    filters?: DeviceFilters,
  ) {
    try {
      this.isSelectLoading = true
      const response = await fetchDevices(this.context, page, pageSize, filters)

      this.selectDevices = response.list
      this.selectDevicesTotal = response.total

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isSelectLoading = false
    }
  }

  @action.bound
  async loadDeviceData(id: string, silentLoading?: boolean) {
    try {
      if (!silentLoading) this.isLoadingData = true
      const deviceData = await fetchDeviceData(this.context, id)

      this.deviceData = deviceData
      return deviceData
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isLoadingData = false
    }
  }

  @action.bound
  async saveDeviceData(id: string, data: DeviceLightDBData) {
    try {
      this.isLoadingData = true

      await storeDeviceData(this.context, id, data)
    } catch (e) {
    } finally {
      this.isLoadingData = false
    }
  }

  @action.bound
  async removeDeviceDataNode(id: string, nodePath: string) {
    try {
      this.isLoadingData = true

      await deleteDeviceDataNode(this.context, id, nodePath)
    } catch (e) {
    } finally {
      this.isLoadingData = false
    }
  }

  @action.bound
  async loadDevicesDetached(
    page: number,
    perPage: number,
    filters: DeviceFilters,
  ) {
    try {
      const response = await fetchDevices(this.context, page, perPage, filters)
      return response
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }
}
