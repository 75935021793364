import jsonexport from 'jsonexport'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import { Key, KeyData } from '../interfaces/IKey'
import { deleteKey, fetchKeys, storeKey } from '../shared/serverApi/keyApi'

export class KeyStore {
  @observable isLoading = false
  @observable isSelectLoading = false
  @observable isExporting = false
  @observable isBulkRemoving = false

  @observable page = 0
  @observable perPage = 10
  @observable total = 1

  @observable keys: Key[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setPagination(page: number, pageSize = 10, total?: number) {
    const newTotal = total ?? this.total
    const lastPage = Math.max(Math.ceil(newTotal / pageSize - 1), 0)
    const newPage = page > lastPage ? lastPage : page

    this.page = newPage
    this.perPage = pageSize
    this.total = newTotal
  }

  @action.bound
  async loadKeys() {
    try {
      this.isLoading = true
      const { page, perPage } = this
      const response = await fetchKeys(page, perPage)

      this.keys = response.list

      this.setPagination(response.page, response.perPage, response.total)

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveKey(data: KeyData) {
    try {
      this.isLoading = true
      const key = await storeKey(data)

      DefaultSuccessMessage('API Key', data.id ? 'updated' : 'created')

      return key
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeKey(id: string) {
    try {
      this.isLoading = true
      await deleteKey(id)
      await this.loadKeys() // TODO: Move to the caller component

      DefaultSuccessMessage('API Key', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveKeys(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(ids.map(id => deleteKey(id)))
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} API Key(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async exportKeys() {
    try {
      this.isExporting = true
      const response = await fetchKeys()
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'keys.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }
}
