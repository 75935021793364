import { useAuth0 } from '@auth0/auth0-react'

export function getLogoutDst(loc: Location) {
  const params = new URLSearchParams(loc.search)
  return params.get('dst')
}

function logoutReturnPoint(loc: Location) {
  const params = new URLSearchParams({
    dst: loc.pathname + loc.search,
  })

  return `${loc.origin}?${params}`
}

/**
 * Get logout function that returns the user to the same
 * location after successful authentication.
 */
export function useLogout() {
  const { logout } = useAuth0()

  const doLogout = () => {
    logout({
      returnTo: logoutReturnPoint(window.location),
    })
  }

  return doLogout
}
