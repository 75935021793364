import { action, makeObservable, observable, runInAction } from 'mobx'
import {
  createPipeline,
  deletePipeline,
  fetchPipeline,
  fetchPipelines,
  updatePipeline,
} from '../shared/serverApi/pipelinesApi'
import {
  Pipeline,
  PipelineData,
  PipelineMetadata,
} from '../interfaces/IPipeline'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import { ProjectContextStore } from './ProjectContextStore'

export class PipelineStore extends ProjectContextStore {
  @observable pipeline: Pipeline | null = null
  @observable isLoadingPipelines = false
  @observable pipelines: PipelineMetadata[] | undefined = undefined

  constructor() {
    super('pipelines')
    makeObservable(this)
  }

  @action.bound
  protected onContextChange() {
    this.pipelines = undefined
    this.pipeline = null
  }

  @action.bound
  async createPipeline(pipeline: PipelineData) {
    try {
      const result = await createPipeline(this.context, pipeline)

      DefaultSuccessMessage('Pipeline', 'created', {
        name: result.name,
      })

      return result
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }

  @action.bound
  async updatePipeline(id: string, pipeline: Partial<PipelineData>) {
    try {
      const result = await updatePipeline(this.context, id, pipeline)
      runInAction(() => {
        if (this.pipeline?.pipelineId === id) {
          this.pipeline = result
        }

        this.pipelines = this.pipelines?.map(p =>
          p.pipelineId === id
            ? {
                pipelineId: result.pipelineId,
                name: result.name,
                enabled: result.enabled,
                createdAt: result.createdAt,
                updatedAt: result.updatedAt,
              }
            : p,
        )
      })

      DefaultSuccessMessage('Pipeline', 'updated', {
        name: result.name,
      })

      return result
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }

  @action.bound
  async deletePipeline(pipelineId: string) {
    try {
      await deletePipeline(this.context, pipelineId)
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }

  @action.bound
  async loadPipeline(pipelineId: string) {
    try {
      const pipeline = await fetchPipeline(this.context, pipelineId)
      runInAction(() => {
        this.pipeline = pipeline
      })
    } catch (e) {
      DefaultErrorNotification(e)
      runInAction(() => {
        this.pipeline = null
      })
    }
  }

  @action.bound
  clearPipeline() {
    this.pipeline = null
  }

  @action.bound
  async loadPipelines() {
    try {
      this.isLoadingPipelines = true
      const pipelines = await fetchPipelines(this.context)
      runInAction(() => {
        this.pipelines = pipelines
      })
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      runInAction(() => {
        this.isLoadingPipelines = false
      })
    }
  }
}
