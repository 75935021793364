import { BulbOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Menu, Modal } from 'antd'
import axios from 'axios'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useStore } from '../hooks/useStore'
import { cannySDK } from '../shared/cannySDK'
import { DefaultErrorNotification } from './DefaultErrorNotification'
import styled from 'styled-components'

export const CannyFeedback = observer(() => {
  const [isVisible, setIsVisible] = useState(false)
  const { token } = useStore('auth')
  const { user } = useAuth0()

  const boardToken = process.env.REACT_APP_CANNY_BOARD_TOKEN
  const cannySSOFunctionUrl = process.env.REACT_APP_CANNY_FUNCTION_URL

  const getCannyToken = async () => {
    try {
      if (!cannySSOFunctionUrl) throw 'Canny SSO function URL not found.'
      const response = await axios.post(
        cannySSOFunctionUrl,
        { user },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data.token
    } catch (e) {
      // Prevent error notification in local dev env:
      if (!cannySSOFunctionUrl?.startsWith('/')) DefaultErrorNotification(e)
    }
  }

  const renderFeedbackWidget = async () => {
    const token = await getCannyToken()
    const canny = cannySDK()

    canny('render', {
      boardToken: boardToken,
      ssoToken: token,
    })
  }

  useEffect(() => {
    if (document.getElementById('canny-iframe')) return
    renderFeedbackWidget()
  }, [])

  return (
    <>
      <CenteredMenu
        mode='inline'
        selectedKeys={isVisible ? ['feedback'] : []}
        items={[
          {
            key: 'feedback',
            icon: <BulbOutlined />,
            label: <span>Feedback</span>,
            onClick: () => setIsVisible(true),
          },
        ]}
      />
      <Modal
        centered
        forceRender
        width={'85%'}
        style={{ maxWidth: 1200 }}
        styles={{
          body: { height: '70vh', padding: '0 14px' },
        }}
        cancelText='Close'
        open={isVisible}
        title='Feedback Management'
        okButtonProps={{ hidden: true }}
        onCancel={() => setIsVisible(false)}
      >
        <div data-canny style={{ height: '100%', overflowY: 'auto' }} />
      </Modal>
    </>
  )
})

const CenteredMenu = styled(Menu)`
  .ant-menu-item {
    justify-content: center;

    .ant-menu-title-content {
      flex-grow: 0 !important;
    }
  }
`
