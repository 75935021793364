import { ReadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { ButtonType } from 'antd/lib/button'
import { PropsWithChildren } from 'react'

interface DocumentationLinkProps {
  icon?: React.ReactNode
  path?: string
  title?: string
  style?: React.CSSProperties
  type?: ButtonType
}

export function DocumentationLink({
  icon,
  path,
  title,
  style,
  type,
  children,
}: PropsWithChildren<DocumentationLinkProps>) {
  return (
    <Tooltip title={title ?? 'Read the documentation'}>
      <Button
        type={type ?? 'link'}
        href={process.env.REACT_APP_DOCS_URL + (path ?? '')}
        style={style}
        icon={icon === undefined ? <ReadOutlined /> : icon}
      >
        {children}
      </Button>
    </Tooltip>
  )
}
