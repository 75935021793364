import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import getStore from './getStore'
import { getProjectRouteParams } from './paths'

export function addApiKeyToRequest(config: AxiosRequestConfig) {
  const { token } = getStore('auth')
  const Authorization = `Bearer ${token}`

  return {
    ...config,
    headers: {
      Authorization,
    },
  }
}

export function addSelectedProjectToRequest(config: AxiosRequestConfig) {
  // No access to routes here:
  const currentProjectId = getProjectRouteParams(
    window.location.pathname,
  ).project

  const baseURL = `${config.baseURL}/projects/${currentProjectId}`

  return {
    ...config,
    baseURL,
  }
}

export function logoutOnUnauthorized(
  logout: () => void,
): (error: AxiosError | Error) => Promise<AxiosError> {
  return (error): Promise<AxiosError> => {
    if (axios.isAxiosError(error)) {
      const { status } = (error.response as AxiosResponse) ?? {}
      if (status === 401) {
        logout()
      }
    }
    return Promise.reject(error)
  }
}
