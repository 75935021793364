import jsonexport from 'jsonexport'
import { action, autorun, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import { Tag, TagData, TagFilters } from '../interfaces/ITag'
import {
  deleteTag,
  fetchTag,
  fetchTags,
  storeTag,
} from '../shared/serverApi/tagApi'
import { ProjectContextStore } from './ProjectContextStore'
import { Paginated } from './Paginated'
import { AsyncCache } from './AsyncCache'

export class TagStore extends ProjectContextStore {
  @observable isLoading = false
  @observable isSelectLoading = false
  @observable isExporting = false
  @observable isBulkRemoving = false

  @observable tags = new Paginated<Tag, TagFilters>(
    ({ page, perPage, filters }) =>
      fetchTags(this.context, page, perPage, filters),
  )

  @observable selectTags: Tag[] = []

  @observable tagNames = new AsyncCache(
    id => fetchTag(this.context, id),
    tag => tag.name,
  )

  constructor() {
    super('tags')
    makeObservable(this)

    autorun(() => this.tagNames.refresh(this.tags))
    autorun(() => this.tagNames.refresh(this.selectTags))
  }

  @action.bound
  protected async onContextChange() {
    this.tagNames.reset()
    this.tags.reset()
    this.selectTags = []
  }

  @action.bound
  async saveTag(data: TagData) {
    try {
      this.isLoading = true
      const tag = await storeTag(this.context, data)
      const action = data.id ? 'updated' : 'created'

      DefaultSuccessMessage('Tag', action, {
        name: data.name,
      })

      return tag
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveTags(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteTag(this.context, id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Tag(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async exportTags() {
    try {
      this.isExporting = true
      const response = await fetchTags(this.context)
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'tags.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }

  @action.bound
  async loadSelectTags() {
    try {
      this.isSelectLoading = true
      const response = await fetchTags(this.context)

      this.selectTags = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isSelectLoading = false
    }
  }
}
