import { useEffect } from 'react'
import { useStore } from './useStore'

export interface PageOptions {
  title: string
  backTarget?: string
}

export function useAsPage({ title, backTarget }: PageOptions) {
  const { organization } = useStore('organization')
  const { project } = useStore('project')
  const { setPageTitle, setBackTarget } = useStore('layout')

  useEffect(() => {
    const parts = [organization?.id, project?.id].filter(Boolean)
    if (parts.length) {
      title = `${title} · ${parts.join('/')}`
    }

    setPageTitle(title)
    setBackTarget(backTarget)
  }, [title, backTarget])
}
