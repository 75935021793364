import axios from 'axios'
import qs from 'qs'
import {
  addApiKeyToRequest,
  addSelectedProjectToRequest,
} from './axiosConfigUtils'

// Axios common instances

export const serverClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const projectServerClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

// Config

// Global Server Client
serverClient.defaults.paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: 'repeat' })
serverClient.interceptors.request.use(addApiKeyToRequest)

// Project Server Client
projectServerClient.defaults.paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: 'repeat' })
projectServerClient.interceptors.request.use(addApiKeyToRequest)
projectServerClient.interceptors.request.use(addSelectedProjectToRequest)
