import {
  ApiOutlined,
  AppstoreOutlined,
  BuildOutlined,
  CloudServerOutlined,
  ControlOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FileZipOutlined,
  LockOutlined,
  MonitorOutlined,
  SafetyOutlined,
  SendOutlined,
  SettingOutlined,
  TagsOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import { FunctionComponent, useState } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { isFeatureActive } from '../../feature-flags'
import { useStore } from '../../hooks/useStore'
import {
  Apikeys,
  Artifacts,
  Blueprints,
  Certificates,
  Devices,
  Devicestream,
  Integrations,
  Logs,
  Pipelines,
  Policies,
  Releases,
  Secrets,
  Settings,
  Tags,
} from '../../interfaces/IPermission'
import { CannyFeedback } from '../CannyFeedback'
import { paths } from '../../shared/paths'
import { PipelineIcon } from '../PipelineIcon'
import {
  projectSections,
  useProjectSection,
} from '../../shared/ProjectSections'
import { notUndefined } from '../../shared/filter'
import { AppRoute } from '../../routes'
import styled from 'styled-components'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const { Sider } = Layout

interface MenuItemBase {
  icon: React.ReactNode
  name: string
  hidden?: boolean
  permission?: string | string[]
}

interface SubMenu extends MenuItemBase {
  items: MenuItem[]
}

interface MenuItem extends MenuItemBase {
  route: AppRoute
  key: typeof projectSections[number]
}

const sidebar: (MenuItem | SubMenu)[] = [
  {
    icon: <AppstoreOutlined />,
    name: 'Devices',
    route: paths.projects.devices.index,
    permission: Devices.Read,
    key: 'device',
  },
  {
    icon: <BuildOutlined />,
    name: 'Blueprints',
    route: paths.projects.blueprints.index,
    permission: Blueprints.Read,
    key: 'blueprint',
  },
  {
    icon: <TagsOutlined />,
    name: 'Tags',
    route: paths.projects.tags,
    permission: Tags.Read,
    key: 'tag',
  },
  {
    icon: <ControlOutlined />,
    name: 'Device Settings',
    route: paths.projects.deviceSettings,
    hidden: !isFeatureActive('device-settings'),
    permission: Settings.Read,
    key: 'device-settings',
  },
  {
    icon: <PipelineIcon />,
    name: 'Pipelines',
    route: paths.projects.pipelines.index,
    hidden: !isFeatureActive('pipelines'),
    permission: Pipelines.Read,
    key: 'pipeline',
  },

  // Monitor Submenu
  {
    icon: <MonitorOutlined />,
    name: 'Monitor',
    items: [
      {
        icon: <FileSearchOutlined />,
        name: 'Logs',
        route: paths.projects.logs,
        permission: Logs.Read,
        key: 'logs',
      },
      {
        icon: <CloudServerOutlined />,
        name: 'LightDB Stream',
        route: paths.projects.stream,
        permission: Devicestream.Read,
        key: 'stream',
      },
    ],
  },

  // Firmware Updates Submenu
  {
    icon: <SendOutlined />,
    name: 'Firmware Updates',
    items: [
      {
        icon: <FileZipOutlined />,
        name: 'Artifacts',
        route: paths.projects.artifacts.index,
        permission: Artifacts.Read,
        key: 'artifact',
      },
      {
        icon: <SendOutlined />,
        name: 'Releases',
        route: paths.projects.releases.index,
        permission: Releases.Read,
        key: 'release',
      },
    ],
  },

  // Credentials Submenu
  {
    icon: <SafetyOutlined />,
    name: 'Credentials',
    items: [
      {
        icon: <ApiOutlined />,
        name: 'API Keys',
        route: paths.projects.apiKeys,
        permission: Apikeys.Read,
        key: 'apikeys',
      },
      {
        icon: <FileProtectOutlined />,
        name: 'Certificates',
        route: paths.projects.certificates,
        permission: Certificates.Read,
        key: 'certificates',
      },
      {
        icon: <LockOutlined />,
        hidden: !isFeatureActive('pipelines'),
        name: 'Secrets',
        route: paths.projects.secrets,
        permission: Secrets.Read,
        key: 'secrets',
      },
    ],
  },
  {
    icon: <SettingOutlined />,
    name: 'Project Settings',
    route: paths.projects.settings,
    key: 'settings',
    permission: [
      Certificates.Read,
      Apikeys.Read,
      Integrations.Read,
      Policies.Read,
    ],
  },
]

function getParentMenu(key: string) {
  return sidebar.find(
    i => 'items' in i && i.items.some(child => child.key === key),
  )
}

export const LayoutSidebar: FunctionComponent = observer(() => {
  const [collapsed, setCollapsed] = useState(false)
  const { currentUserPermissions } = useStore('access')
  const { project } = useStore('project')
  const { lg } = useBreakpoint()

  const selectedKey = useProjectSection() ?? 'device'
  const parent = getParentMenu(selectedKey)

  // Ensure that the active menu item is visible on mount:
  const [openKeys, setOpenKeys] = useState<string[]>(
    parent ? [parent.name] : [],
  )

  if (!project || currentUserPermissions.length === 0) {
    return null
  }

  const isVisible = (item: MenuItemBase) => {
    return (
      !item.hidden &&
      (item.permission === undefined ||
        currentUserPermissions.some(
          permission =>
            permission === item.permission ||
            item.permission?.includes(permission),
        ))
    )
  }

  const menuItem = (i: MenuItem) => ({
    key: i.key,
    icon: i.icon,
    label: (
      <Link
        to={generatePath(i.route, {
          org: project.organizationId,
          project: project.id,
        })}
      >
        {i.name}
      </Link>
    ),
    permission: i.permission,
    hidden: i.hidden,
  })

  const menuItems = sidebar
    .filter(isVisible)
    .map(i => {
      if ('route' in i) {
        return menuItem(i)
      }

      // submenus:
      const children = i.items.filter(isVisible).map(menuItem)
      if (children.length) {
        return {
          key: i.name,
          icon: i.icon,
          label: i.name,
          children,
        }
      }
    })
    .filter(notUndefined)

  return (
    <Sidebar
      theme='light'
      breakpoint='lg'
      collapsible
      collapsedWidth={lg ? undefined : 48}
      width={210}
      role='navigation'
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <Menu
        mode='inline'
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        selectedKeys={selectedKey && [selectedKey]}
        itemRef='menu'
        items={menuItems}
      />
      <CannyFeedback />
    </Sidebar>
  )
})

const Sidebar = styled(Sider)`
  // Ensure the sidebar is full height and feedback is at the bottom:
  .ant-layout-sider-children {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`
