import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import { Key, KeyData } from '../../interfaces/IKey'
import { projectServerClient } from '../apiClients'

export async function fetchKeys(page?: number, perPage?: number) {
  try {
    const response = await projectServerClient.get<PaginatedListResponse<Key>>(
      `/apikeys`,
      {
        params: { page, perPage },
      },
    )

    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchKey(id: string) {
  try {
    const response = await projectServerClient.get<Response<Key>>(
      `/apikeys/${id}`,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function storeKey(data: KeyData) {
  try {
    const dynamicMethod = data.id
      ? projectServerClient.patch
      : projectServerClient.post
    const dynamicEndpoint = data.id ? `/apikeys/${data.id}` : `/apikeys`
    const response = await dynamicMethod<Response<Key>>(dynamicEndpoint, data)

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteKey(id: string) {
  try {
    await projectServerClient.delete(`/apikeys/${id}`)
  } catch (e) {
    throw e
  }
}
