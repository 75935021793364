import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { CheckoutItem } from '../interfaces/IBilling'
import {
  fetchCheckoutSession,
  fetchCustomerPortal,
} from '../shared/serverApi/billingApi'

export class BillingStore {
  @observable isPlansLoading = false
  @observable isCheckoutLoading = false
  @observable isCustomPortalLoading = false

  constructor() {
    makeObservable(this)
  }

  @action.bound
  async loadCheckoutSessionLink(
    orgId: string,
    items: CheckoutItem[],
    callbackPath: string,
  ) {
    try {
      this.isCheckoutLoading = true

      const link = await fetchCheckoutSession(orgId, items, callbackPath)

      return link
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isCheckoutLoading = false
    }
  }

  @action.bound
  async loadCustomerPortalLink(orgId: string, callbackPath: string) {
    try {
      this.isCustomPortalLoading = true

      const link = await fetchCustomerPortal(orgId, callbackPath)

      return link
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isCustomPortalLoading = false
    }
  }
}
