import { ProjectContext } from '../interfaces/IProject'

class ContextError extends Error {
  constructor(resources: string) {
    super(`Access to ${resources} requires an active project`)
  }
}

/**
 * Abstract store class that relies on a project context to run a set of actions.
 *
 * When the context is updated, this calls the reset method, allowing the store to
 * flush any state that depends on the context.
 */
export abstract class ProjectContextStore {
  private _context: ProjectContext | undefined

  constructor(private readonly resources: string) {}

  async setContext(ctx: ProjectContext | undefined) {
    this._context = ctx
    await this.onContextChange(ctx)
  }

  protected get context() {
    if (!this._context) throw new ContextError(this.resources)

    return this._context
  }

  protected abstract onContextChange(
    context: ProjectContext | undefined,
  ): void | Promise<void>
}
