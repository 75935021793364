import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import {
  Device,
  DeviceActivity,
  DeviceData,
  DeviceFilters,
  DeviceLightDBData,
} from '../../interfaces/IDevice'
import { ProjectContext } from '../../interfaces/IProject'
import { ProvisionData } from '../../interfaces/IProvision'
import { serverClient } from '../apiClients'

function url({ project }: ProjectContext, subpath?: string) {
  const base = `/projects/${project}/devices`

  return subpath ? `${base}/${subpath}` : base
}

export async function fetchDevices(
  ctx: ProjectContext,
  page?: number,
  perPage?: number,
  filters?: DeviceFilters,
) {
  const response = await serverClient.get<PaginatedListResponse<Device>>(
    url(ctx),
    {
      params: { page, perPage, ...filters },
    },
  )

  return response.data
}

export async function fetchDevice(ctx: ProjectContext, id: string) {
  const response = await serverClient.get<Response<Device>>(url(ctx, id))

  return response.data.data
}

export async function storeDevice(
  ctx: ProjectContext,
  data: Partial<DeviceData>,
) {
  if (data.id) {
    const response = await serverClient.put<Response<Device>>(
      url(ctx, data.id),
      data,
    )

    return response.data.data
  } else {
    const response = await serverClient.post<Response<Device>>(url(ctx), data)

    return response.data.data
  }
}

export async function patchDevice(
  ctx: ProjectContext,
  id: string,
  data: Partial<DeviceData>,
) {
  const response = await serverClient.patch<Response<Device>>(
    url(ctx, id),
    data,
  )

  return response.data.data
}

export async function deleteDevice(ctx: ProjectContext, id: string) {
  await serverClient.delete(url(ctx, id))
}

export async function fetchDevicesActivity(
  ctx: ProjectContext,
  page?: number,
  perPage?: number,
) {
  const response = await serverClient.get<
    PaginatedListResponse<DeviceActivity>
  >(`/projects/${ctx.project}/device-activity-logs`, {
    params: { page, perPage },
  })

  return response.data
}

export async function provisionDeviceAndCredentials(
  ctx: ProjectContext,
  data: ProvisionData,
) {
  const response = await serverClient.post<Response<{ deviceId: string }>>(
    `/projects/${ctx.project}/provisioning`,
    data,
  )

  return response.data.data.deviceId
}

export async function fetchDeviceData(ctx: ProjectContext, id: string) {
  const response = await serverClient.get<Response<DeviceLightDBData>>(
    url(ctx, `${id}/data`),
  )

  return response.data.data ?? {}
}

export async function storeDeviceData(
  ctx: ProjectContext,
  id: string,
  data: DeviceLightDBData,
) {
  const response = await serverClient.put<Response<DeviceLightDBData>>(
    url(ctx, `${id}/data`),
    data,
  )

  return response.data.data ?? {}
}

export async function deleteDeviceDataNode(
  ctx: ProjectContext,
  id: string,
  nodePath: string,
) {
  const response = await serverClient.delete(url(ctx, `${id}/data${nodePath}`))

  return response.data.data
}
