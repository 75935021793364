import { observer } from 'mobx-react-lite'
import { FunctionComponent, useEffect, useState } from 'react'
import { useStore } from '../../hooks/useStore'
import { useParams } from 'react-router-dom'
import { LayoutLoader } from './LayoutLoader'

export const OrgLayout: FunctionComponent = observer(({ children }) => {
  const { loadOrganization } = useStore('organization')
  /* Using local isLoading state instead of the store's, as we don't want this
   * component to show as loading while we're doing other store loading, like the org quotas.
   */
  const [isLoading, setIsLoading] = useState(true)
  const params = useParams<{ org: string }>()

  const loadCurrentOrg = async () => {
    setIsLoading(true)
    await loadOrganization(params.org)
    setIsLoading(false)
  }

  useEffect(() => {
    loadCurrentOrg()
  }, [params.org])

  return (
    <LayoutLoader id='organization' isLoading={isLoading}>
      {children}
    </LayoutLoader>
  )
})
