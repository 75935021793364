import {
  ListResponse,
  PaginatedListResponse,
  Response,
} from '../../interfaces/IApiResponses'
import { Permission } from '../../interfaces/IPermission'
import { Policy, PolicyFilters } from '../../interfaces/IPolicy'
import { Role } from '../../interfaces/IRole'
import { User, UserData } from '../../interfaces/IUser'
import { projectServerClient, serverClient } from '../apiClients'

export async function fetchUsers(
  projectId: string,
  page?: number,
  perPage?: number,
) {
  try {
    const response = await serverClient.get<ListResponse<User>>(
      `/projects/${projectId}/users`,
      {
        params: { page, perPage },
      },
    )

    return response.data
  } catch (e) {
    throw e
  }
}

export async function inviteUser(projectId: string, data: UserData) {
  try {
    const response = await serverClient.post<Policy>(
      `/projects/${projectId}/users/invitation`,
      data,
    )

    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchPolicies(
  page?: number,
  perPage?: number,
  filters?: PolicyFilters,
) {
  try {
    const response = await projectServerClient.get<
      PaginatedListResponse<Policy>
    >(`/policies`, {
      params: { page, perPage, ...filters },
    })

    return response.data
  } catch (e) {
    throw e
  }
}

export async function putPolicy(projectId: string, data: Partial<Policy>) {
  try {
    const response = await serverClient.put<Response<Policy>>(
      `/projects/${projectId}/policies/${data.id}`,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deletePolicy(projectId: string, policyId: string) {
  try {
    await serverClient.delete(`/projects/${projectId}/policies/${policyId}`)
  } catch (e) {
    throw e
  }
}

export async function fetchPermissions(projectId: string) {
  try {
    const response = await serverClient.get<ListResponse<Permission>>(
      `/projects/${projectId}/permissions`,
    )

    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchRoles() {
  try {
    const response = await serverClient.get<ListResponse<Role>>(`/roles`)

    return response.data
  } catch (e) {
    throw e
  }
}
