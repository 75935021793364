import { Button, Typography } from 'antd'
import { generatePath, useHistory } from 'react-router-dom'
import { paths } from '../../shared/paths'
import { SplashScreen } from '../../components/layout/SplashScreen'
import { useLogout } from '../../hooks/useLogout'

const { Text } = Typography

export default function VerifyEmail() {
  const logout = useLogout()
  const { replace } = useHistory()
  return (
    <SplashScreen style={{ width: 450 }}>
      <h1>Please verify your email address</h1>
      <Text style={{ fontSize: '1rem' }}>
        To start using the Golioth Console, please verify your email address by
        clicking the link we sent you.{' '}
      </Text>
      <Text type='secondary'>
        If you didn{"'"}t receive an email from us, please check your spam
        folder or contact{' '}
        <a href='mailto:support@golioth.io'>support@golioth.io</a>.
      </Text>
      <Button
        block
        style={{ marginTop: '2rem' }}
        onClick={() => replace(generatePath(paths.login))}
        type='primary'
        size='large'
      >
        Reload
      </Button>
      <Button block onClick={logout}>
        Try a different account
      </Button>
    </SplashScreen>
  )
}
