import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { Credential, CredentialData } from '../interfaces/ICredential'
import {
  deleteCredential,
  fetchDeviceCredentials,
  storeCredential,
} from '../shared/serverApi/credentialsApi'

export class CredentialStore {
  @observable isLoading = false
  @observable isExporting = false
  @observable isBulkRemoving = false

  @observable credentials: Credential[] = []
  @observable credential = {} as Credential

  constructor() {
    makeObservable(this)
  }

  @action.bound
  async loadDeviceCredentials(deviceId: string) {
    try {
      this.isLoading = true
      const response = await fetchDeviceCredentials(deviceId)

      this.credentials = response

      return response
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveCredential(deviceId: string, data: CredentialData) {
    try {
      this.isLoading = true
      const device = await storeCredential(deviceId, data)

      this.credential = device
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeCredential(deviceId: string, credentialId: string) {
    try {
      this.isLoading = true
      await deleteCredential(deviceId, credentialId)
      await this.loadDeviceCredentials(deviceId)
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveCredentials(deviceId: string, ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteCredential(deviceId, id)),
      )
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }
}
