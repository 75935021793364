import { AccessStore } from '../stores/AccessStore'
import { ArtifactStore } from '../stores/ArtifactStore'
import { AuthStore } from '../stores/AuthStore'
import { BillingStore } from '../stores/BillingStore'
import { BlueprintStore } from '../stores/BlueprintStore'
import { BoardStore } from '../stores/BoardsStore'
import { CredentialStore } from '../stores/CredentialStore'
import { DeviceLightDBStore } from '../stores/DeviceLightDBStore'
import { DeviceSettingStore } from '../stores/DeviceSettingStore'
import { DeviceStore } from '../stores/DeviceStore'
import { OutputStreamStore } from '../stores/OutputStreamStore'
import { KeyStore } from '../stores/KeyStore'
import { LayoutStore } from '../stores/LayoutStore'
import { LightDBStore } from '../stores/LightDBStore'
import { LogStore } from '../stores/LogStore'
import { OrganizationStore } from '../stores/OrganizationStore'
import { ProjectCertificateStore } from '../stores/ProjectCertificateStore'
import { ProjectStore } from '../stores/ProjectStore'
import { ReleaseStore } from '../stores/ReleaseStore'
import { TagStore } from '../stores/TagStore'
import { PipelineStore } from '../stores/PipelineStore'
import { SecretStore } from '../stores/SecretStore'

/**
 * List with all stores.
 */
export interface AppStores {
  layout: LayoutStore
  auth: AuthStore
  project: ProjectStore
  device: DeviceStore
  credential: CredentialStore
  tag: TagStore
  log: LogStore
  key: KeyStore
  lightDB: LightDBStore
  blueprint: BlueprintStore
  artifact: ArtifactStore
  release: ReleaseStore
  outputStream: OutputStreamStore
  access: AccessStore
  deviceLightDB: DeviceLightDBStore
  board: BoardStore
  organization: OrganizationStore
  billing: BillingStore
  deviceSettings: DeviceSettingStore
  projectCertificate: ProjectCertificateStore
  pipeline: PipelineStore
  secret: SecretStore
}

/**
 * Initiate all app's stores
 */
function createStores(): AppStores {
  return {
    layout: new LayoutStore(),
    auth: new AuthStore(),
    project: new ProjectStore(),
    device: new DeviceStore(),
    credential: new CredentialStore(),
    tag: new TagStore(),
    log: new LogStore(),
    key: new KeyStore(),
    lightDB: new LightDBStore(),
    blueprint: new BlueprintStore(),
    artifact: new ArtifactStore(),
    release: new ReleaseStore(),
    outputStream: new OutputStreamStore(),
    access: new AccessStore(),
    deviceLightDB: new DeviceLightDBStore(),
    board: new BoardStore(),
    organization: new OrganizationStore(),
    billing: new BillingStore(),
    deviceSettings: new DeviceSettingStore(),
    projectCertificate: new ProjectCertificateStore(),
    pipeline: new PipelineStore(),
    secret: new SecretStore(),
  }
}

export default createStores
