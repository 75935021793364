import jsonexport from 'jsonexport'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import { Project, ProjectData, ProjectFilters } from '../interfaces/IProject'
import {
  deleteProject,
  fetchProject,
  fetchProjects,
  storeProject,
} from '../shared/serverApi/projectApi'
import { lazyObservable } from 'mobx-utils'

export class ProjectStore {
  @observable isLoading = false
  @observable isBulkRemoving = false
  @observable isExporting = false

  @observable project: Project | null = null
  @observable projects: Project[] = []
  selectProjects = lazyObservable<Project[]>(sink => fetchProjects().then(sink))

  constructor() {
    makeObservable(this)
  }

  @action.bound
  loadAvailableProjectsIds(userId: string) {
    return Number(
      localStorage.getItem(`${userId}.golioth.available.project.ids`) ?? 0,
    )
  }

  @action.bound
  async loadProjects(filters?: ProjectFilters) {
    try {
      this.isLoading = true
      const projects = await fetchProjects(filters)

      this.projects = projects || []

      return projects
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async loadProject(id: string) {
    try {
      this.isLoading = true
      this.project = await fetchProject(id)
      return this.project
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  clearProject() {
    this.project = null
  }

  @action.bound
  async saveProject(data: ProjectData) {
    try {
      this.isLoading = true
      const project = await storeProject(data)
      if (this.project?.id === project.id) {
        this.project = project
      }
      this.selectProjects.reset()

      return project
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeProject(id: string) {
    try {
      this.isLoading = true
      await deleteProject(id)
      this.selectProjects.reset()

      DefaultSuccessMessage('Project', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveProjects(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(ids.map(id => deleteProject(id)))
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      this.selectProjects.reset()

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Project(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async exportProjects() {
    try {
      this.isExporting = true
      const csv = await jsonexport(this.projects)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'projects.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }
}
