import { GiftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FunctionComponent, useEffect } from 'react'
import { cannySDK } from '../shared/cannySDK'

export const CannyChangelog: FunctionComponent = () => {
  const initCannyChangelog = async () => {
    const canny = await cannySDK()

    canny('initChangelog', {
      appID: '60ccd51f4511e94f1c7c524b',
      position: 'bottom',
      align: 'right',
    })
  }

  useEffect(() => {
    initCannyChangelog()
  }, [])

  return (
    <Button
      shape='circle'
      type='text'
      data-canny-changelog
      icon={<GiftOutlined />}
    />
  )
}
