import { FunctionComponent } from 'react'
import { useAsPage } from '../../hooks/useAsPage'
import { EchoResult } from '../../components/EchoResult'

const DisabledRoute: FunctionComponent = () => {
  useAsPage({
    title: 'Under Construction',
  })

  return (
    <EchoResult
      title='New feature coming soon!'
      message='This feature isn’t ready yet, but we’re working on it.'
    />
  )
}

export default DisabledRoute
