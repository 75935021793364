import { Space, SpaceProps } from 'antd'
import { FunctionComponent } from 'react'

/** Commonly used vertical spacer that fills the width of the parent */
export const VerticalSpace: FunctionComponent<Omit<SpaceProps, 'direction'>> =
  ({ style, children, ...props }) => {
    return (
      <Space
        direction='vertical'
        style={{ width: '100%', ...style }}
        {...props}
      >
        {children}
      </Space>
    )
  }
