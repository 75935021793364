import { Menu, Space, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { generatePath, useHistory } from 'react-router-dom'
import {
  PlusOutlined,
  ProjectOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { paths } from '../../shared/paths'
import { useOrgTree } from '../../hooks/useOrgTree'
import { notUndefined } from '../../shared/filter'
import { ItemType } from 'rc-menu/lib/interface'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useProjectSectionIndex } from '../../shared/ProjectSections'

interface OrgProjectSwitcherProps {
  project?: string
  onSelect: () => void
}

const DIVIDER: ItemType = {
  type: 'divider',
  style: { borderColor: '#cccccc' },
}

export const OrgProjectSwitcher = observer(
  ({ project, onSelect }: OrgProjectSwitcherProps) => {
    const orgTree = useOrgTree()
    const { push } = useHistory()
    const { md } = useBreakpoint()
    const route = useProjectSectionIndex()

    const compact = !md

    const handleClick = (path: string) => {
      push(path)
      onSelect()
    }

    const orgs = (orgTree ?? []).map(o => {
      const orgProjects =
        o.projects.map(p => {
          return {
            label: p.id,
            key: p.id,
            className: 'org-project-switcher-project',
            onClick: () =>
              handleClick(
                generatePath(route ?? paths.projects.devices.index, {
                  org: p.organizationId,
                  project: p.id,
                }),
              ),
          } as ItemType
        }) ?? []

      const items = new Array<ItemType>()
      if (orgProjects.length) {
        if (compact) {
          /* Skip group title */
          items.push(...orgProjects)
        } else {
          items.push({
            type: 'group',
            label: (
              <Space>
                <ProjectOutlined />
                <span>Projects</span>
              </Space>
            ),
            children: orgProjects,
          })
        }
      }

      if (o.admin) {
        if (orgProjects.length) items.push(DIVIDER)

        items.push(
          {
            label: (
              <Space>
                <PlusOutlined />
                Create Project
              </Space>
            ),
            key: o.id + '-create-project',
            onClick: () => {
              handleClick(generatePath(paths.projects.create, { org: o.id }))
            },
          },
          {
            label: (
              <Space>
                <SettingOutlined />
                Settings
              </Space>
            ),
            key: o.id + '-settings',
            onClick: () => {
              handleClick(generatePath(paths.orgs.settings, { org: o.id }))
            },
          },
        )
      }
      return {
        label: o.id,
        key: o.id,
        popupClassName: 'org-project-switcher-popup',
        children: items,
      } as ItemType
    })

    const items = [...orgs]

    if (!orgTree) {
      items.push({
        label: (
          <Space align='center'>
            <Spin spinning />
            <span>Loading...</span>
          </Space>
        ),
        key: 'loading',
        disabled: true,
      })
    }

    if (orgs.length) items.push(DIVIDER)

    items.push({
      label: (
        <Space>
          <PlusOutlined />
          Create Organization
        </Space>
      ),
      key: 'create-org',
      onClick: () => {
        handleClick(generatePath(paths.orgs.create))
      },
    })

    return (
      <Menu
        items={items}
        className='org-project-switcher-menu'
        inlineIndent={0}
        selectedKeys={[project].filter(notUndefined)}
        mode={compact ? 'inline' : 'vertical'}
        style={{ border: 'none', width: 300 }}
      />
    )
  },
)
