import { ListResponse, Response } from '../../interfaces/IApiResponses'
import {
  Organization,
  OrganizationData,
  QuotasType,
} from '../../interfaces/IOrganization'
import { serverClient } from '../apiClients'

export async function fetchOrganizations() {
  try {
    const response = await serverClient.get<ListResponse<Organization>>(
      'organizations',
    )

    return response.data.list
  } catch (e) {
    throw e
  }
}

export async function fetchOrganization(id: string) {
  try {
    const response = await serverClient.get<Response<Organization>>(
      `organizations/${id}`,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function fetchOrganizationQuotas(id: string) {
  try {
    const response = await serverClient.get<Response<QuotasType>>(
      `organizations/${id}/quotas`,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function storeOrganization(data: OrganizationData) {
  try {
    const dynamicMethod = data.id ? serverClient.put : serverClient.post
    const dynamicEndpoint = data.id
      ? `organizations/${data.id}`
      : 'organizations'
    const response = await dynamicMethod<Response<Organization>>(
      dynamicEndpoint,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteOrganization(id: string) {
  try {
    await serverClient.delete(`organizations/${id}`)
  } catch (e) {
    throw e
  }
}
