import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'

type getAccessToken = (
  options?: GetTokenSilentlyOptions | undefined,
) => Promise<string>
export class AuthStore {
  @observable isLoading = false
  @observable isLoadingMetadata = false

  @observable token = ''

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setToken(token: string) {
    this.token = token
  }

  @action.bound
  async retrieveAuthToken(getAccessTokenSilently: getAccessToken) {
    try {
      this.isLoading = true
      const token = await getAccessTokenSilently()

      this.setToken(token)
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isLoading = false
    }
  }
}
