import { notification } from 'antd'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import {
  OutputStream,
  OutputStreamData,
  OutputStreamType,
} from '../interfaces/IOutputStream'
import {
  deleteOutputStream,
  fetchOutputStream,
  fetchOutputStreams,
  fetchOutputStreamTypes,
  storeOutputStream,
  testOutputStream,
} from '../shared/serverApi/outputStreamApi'

export class OutputStreamStore {
  @observable isLoading = false
  @observable isLoadingTypes = false
  @observable isLoadingEvents = false
  @observable isBulkRemoving = false
  @observable isTestingOutputStream = false

  @observable page = 0
  @observable perPage = 0
  @observable total = 1

  @observable outputStream = {} as OutputStream
  @observable outputStreams: OutputStream[] = []
  @observable outputStreamTypes: OutputStreamType[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setPagination(page: number, pageSize = 10, total?: number) {
    const newTotal = total ?? this.total
    const lastPage = Math.max(Math.ceil(newTotal / pageSize - 1), 0)
    const newPage = page > lastPage ? lastPage : page

    this.page = newPage
    this.perPage = pageSize
    this.total = newTotal
  }

  @action.bound
  async loadOutputStreams(silentLoad?: boolean) {
    try {
      if (!silentLoad) this.isLoading = true
      const { page, perPage } = this
      const response = await fetchOutputStreams(page, perPage)

      this.outputStreams = response.list

      this.setPagination(response.page, response.perPage, response.total)

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async loadOutputStream(id: string, silentLoad?: boolean) {
    try {
      if (!silentLoad) this.isLoading = true
      const outputStream = await fetchOutputStream(id)

      this.outputStream = outputStream
      return outputStream
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveOutputStream(data: OutputStreamData, silentLoad?: boolean) {
    try {
      if (!silentLoad) this.isLoading = true
      const outputStream = await storeOutputStream(data)
      const action = data.id ? 'updated' : 'created'

      this.outputStream = outputStream

      DefaultSuccessMessage('Output Stream', action, {
        name: data.name,
      })

      return outputStream
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeOutputStream(id: string) {
    try {
      this.isLoading = true
      await deleteOutputStream(id)
      await this.loadOutputStreams() // TODO: Move to the caller component

      DefaultSuccessMessage('Output Stream', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveOutputStreams(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteOutputStream(id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Output Stream(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async sendOutputStreamTest(id: string, data: Record<string, unknown>) {
    try {
      this.isTestingOutputStream = true

      await testOutputStream(id, data)

      notification.success({
        message: 'Test event send!',
      })
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isTestingOutputStream = false
    }
  }

  @action.bound
  async loadOutputStreamTypes() {
    try {
      this.isLoadingTypes = true
      const response = await fetchOutputStreamTypes()

      this.outputStreamTypes = response.list
      return response
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoadingTypes = false
    }
  }
}
