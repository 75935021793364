import { Row, Tooltip } from 'antd'
import { blue, red } from '@ant-design/colors'
import { PropsWithChildren, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'

type BannerType = 'info' | 'warning' | 'error'

interface DismissableBannerProps {
  id: string
  type?: BannerType
}

const styles: Record<BannerType, React.CSSProperties> = {
  info: { backgroundColor: blue[1] },
  warning: { backgroundColor: '#ffcb47' },
  error: { backgroundColor: red[5] },
}

export function DismissableBanner({
  id,
  type,
  children,
}: PropsWithChildren<DismissableBannerProps>) {
  const [visible, setVisible] = useState<boolean>(
    !JSON.parse(
      localStorage.getItem(`golioth.banner.${id}.dismissed`) ?? 'false',
    ),
  )

  if (!visible) {
    return null
  }

  const handleBannerClose = () => {
    setVisible(false)
    localStorage.setItem(`golioth.banner.${id}.dismissed`, 'true')
  }

  return (
    <Banner style={{ ...styles[type ?? 'info'], position: 'relative' }}>
      <Row
        justify='space-between'
        align='top'
        style={{ width: '100%', paddingRight: 20 }}
      >
        {children}
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <Tooltip title='Dismiss'>
            <CloseOutlined aria-label='Dismiss' onClick={handleBannerClose} />
          </Tooltip>
        </div>
      </Row>
    </Banner>
  )
}

export const Banner = styled.div`
  width: 100%;
  padding: 14px;
  padding-left: 28px;
  display: flex;
  min-height: 100px;
  background-color: #d7e4ff;
  margin-bottom: 16px;

  h1 {
    color: inherit;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
`
