import { PaginatedListResponse } from '../../interfaces/IApiResponses'
import { Log, LogFilters } from '../../interfaces/ILog'
import { projectServerClient } from '../apiClients'

export async function fetchLogs(
  page?: number,
  perPage?: number,
  filters?: LogFilters,
) {
  try {
    const response = await projectServerClient.get<PaginatedListResponse<Log>>(
      `/logs`,
      {
        params: { page, perPage, ...filters },
      },
    )

    return response.data
  } catch (e) {
    throw e
  }
}
