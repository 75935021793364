import { Layout as AntdLayout } from 'antd'
import { observer } from 'mobx-react-lite'
import { FunctionComponent } from 'react'
import { LayoutHeader } from './Header'
import { LayoutSidebar } from './Sidebar'
const { Content } = AntdLayout

const Layout: FunctionComponent = observer(({ children }) => {
  return (
    <AntdLayout style={{ height: '100vh', minWidth: '100%' }}>
      <LayoutHeader />
      <AntdLayout style={{ width: '100%', position: 'relative' }}>
        <LayoutSidebar />
        <AntdLayout
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
            boxSizing: 'border-box',
            justifyContent: 'center',
            flexDirection: 'row',
            padding: 16,
          }}
        >
          <Content style={{ maxWidth: 1920, width: '100%' }}>
            {children}
          </Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  )
})

export default Layout
