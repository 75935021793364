import { LoadingOutlined } from '@ant-design/icons'
import * as antd from 'antd'
import { Auth0Provider } from '@auth0/auth0-react'
import { Spin } from 'antd'
import { createBrowserHistory } from 'history'
import { configure } from 'mobx'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { FrigadeProvider } from '@frigade/react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Router } from 'react-router-dom'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { LayoutLoaderSpinner } from './components/layout/LayoutLoader'
import { goliothColors, goliothLightTheme } from './theme'
import { SimulatorContextProvider } from './components/DeviceSimulator'

// history
export const history = createBrowserHistory()

// Antd configs
Spin.setDefaultIndicator(<LoadingOutlined spin />)

// MobX configs
configure({
  enforceActions: 'never',
})

// PostHog configs
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY!, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  opt_in_site_apps: true,
  // Feature flags are reloaded when user is identified, so avoid fetching twice
  // on every load.
  // https://posthog.com/docs/feature-flags/common-questions#1-reduce-client-side-feature-flag-requests
  advanced_disable_feature_flags_on_first_load: true,
  // Recording cross-origin iframes allows for capturing data from the Golioth
  // Simulator. It is imperative that proper content security policies are
  // employed when this posthog functionality is enabled. In the case of the
  // Golioth Simulator, the console is acting as the iframe embedder, but
  // recordCrossOriginIframes will cause posthog to post messages to any
  // embedder, meaning that the console would post to an ancestor if it was
  // embedded as an iframe. To guard against this, frame-ancestors should be
  // always set to 'none' for the console.
  session_recording: {
    recordCrossOriginIframes: true,
  },
})

ReactDOM.render(
  <Auth0Provider
    useRefreshTokens
    useRefreshTokensFallback={false}
    cacheLocation='localstorage'
    redirectUri={window.location.origin}
    scope={process.env.REACT_APP_AUTH0_SCOPE}
    domain={process.env.REACT_APP_AUTH0_DOMAIN!}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
  >
    {/* Disable StrictMode while antd does not support it
      https://github.com/ant-design/ant-design/issues/26136
      <React.StrictMode> */}
    <PostHogProvider>
      <FrigadeProvider
        publicApiKey={process.env.REACT_APP_PUBLIC_FRIGADE_KEY || ''}
        config={{
          defaultAppearance: {
            theme: {
              colorText: goliothColors.ConnectiveCoral,
              colorTextSecondary: '#494949',
              colorTextOnPrimaryBackground: '#fff',
              colorPrimary: goliothColors.ConnectiveCoral,
              colorBorder: goliothColors.ConnectiveCoral,
            },
            styleOverrides: {
              smallTitle: {
                fontSize: '1rem',
                fontWeight: 'lighter',
              },
              button: {
                borderRadius: 0,
                backgroundColor: '#fff',
                color: '#2E3AFF',
                borderColor: '#2E3AFF',
              },
            },
          },
        }}
      >
        <antd.ConfigProvider theme={goliothLightTheme}>
          <HelmetProvider>
            <Router history={history}>
              <SimulatorContextProvider>
                <LayoutLoaderSpinner>
                  <App />
                </LayoutLoaderSpinner>
              </SimulatorContextProvider>
            </Router>
          </HelmetProvider>
        </antd.ConfigProvider>
      </FrigadeProvider>
    </PostHogProvider>
    {/* </React.StrictMode> */}
  </Auth0Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
