import { useAuth0 } from '@auth0/auth0-react'
import { observer } from 'mobx-react-lite'
import { FunctionComponent } from 'react'
import { Redirect, Route, generatePath, useHistory } from 'react-router-dom'
import { useStore } from '../../hooks/useStore'
import { ResolvedRoute } from '../../routes'
import DisabledRoute from '../../views/disabled-route/DisabledRoute'
import NotAuthorized from '../../views/not-authenticated/NotAuthorized'
import { paths } from '../../shared/paths'
import VerifyEmail from '../../views/not-authenticated/VerifyEmail'
import { useIsEmailVerified } from '../../hooks/useIsEmailVerified'
import { useLogout } from '../../hooks/useLogout'

interface PrivateRouteProps extends ResolvedRoute {
  path: string
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = observer(
  ({ requiredPermissions, hideRoute, ...routeProps }) => {
    const { isAuthenticated, error } = useAuth0()
    const logout = useLogout()
    const { goBack, location } = useHistory()
    const isEmailVerified = useIsEmailVerified()
    const { currentUserPermissions } = useStore('access')

    const hasPermission =
      !requiredPermissions ||
      requiredPermissions.every(p => currentUserPermissions.includes(p!))

    if (!isEmailVerified) {
      return <VerifyEmail />
    }

    if (error) {
      return (
        <NotAuthorized
          error={error}
          description='Something went wrong with your authentication'
          callback={{
            label: 'Retry Login',
            onClick: logout,
          }}
        />
      )
    }

    if (!isAuthenticated)
      return (
        <Redirect
          key='redirect'
          to={generatePath(paths.login) + location.search}
        />
      )

    if (hideRoute) return <DisabledRoute />

    if (!hasPermission) {
      const list = requiredPermissions?.join(', ')
      return (
        <NotAuthorized
          error={`User does not have all required permissions: ${list}.`}
          callback={{
            label: 'Go Back',
            onClick: () => goBack(),
          }}
        />
      )
    }

    return <Route {...routeProps} />
  },
)

export default PrivateRoute
