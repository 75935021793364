import { PaginatedListResponse } from '../../interfaces/IApiResponses'
import {
  LightDBStreamFilters,
  LightDBStreamQuery,
  LightDBStreamResponse,
} from '../../interfaces/ILightDBStream'
import { projectServerClient } from '../apiClients'

export async function fetchLightDBStream(
  query: LightDBStreamQuery,
  page?: number,
  perPage?: number,
  filters?: LightDBStreamFilters,
) {
  try {
    const response = await projectServerClient.post<
      PaginatedListResponse<LightDBStreamResponse>
    >(`/stream`, {
      query,
      page,
      perPage,
      ...filters,
    })

    return response.data
  } catch (e) {
    throw e
  }
}
