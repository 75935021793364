import { LeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Popover } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { observer } from 'mobx-react-lite'
import { FunctionComponent, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useStore } from '../../hooks/useStore'
import { OrgProjectSwitcher } from './OrgProjectSwitcher'
import { getProjectRouteParams } from '../../shared/paths'
import logo from '../../assets/images/golioth.svg'

function CompactLogo({ onClick }: { onClick?: () => void }) {
  return (
    <img
      src={logo}
      style={{ height: 30, cursor: 'pointer' }}
      onClick={onClick}
    />
  )
}

const NavigationBreadcrumbs: FunctionComponent = observer(() => {
  const { backTarget } = useStore('layout')
  const { pathname } = useLocation()
  // no access to routes here:
  const { org, project } = getProjectRouteParams(pathname)
  const { md, xl } = useBreakpoint()
  const [switcherIsOpen, setSwitcherIsOpen] = useState(false)

  if (backTarget) {
    return (
      <Navigation>
        {!md && <CompactLogo />}
        <Breadcrumb
          items={[
            {
              href: backTarget,
              title: (
                <>
                  <LeftOutlined /> {xl && <>Back</>}
                </>
              ),
            },
          ]}
        />
      </Navigation>
    )
  }

  if (!org) {
    // no org, no breadcrumbs
    if (md) {
      return null
    }

    return <CompactLogo />
  }

  const breadcrumbs = [org]
  if (project) {
    breadcrumbs.push(project)
  }

  return (
    <Navigation>
      <Popover
        overlayClassName='org-project-switcher'
        content={
          <OrgProjectSwitcher
            onSelect={() => setSwitcherIsOpen(false)}
            project={project}
          />
        }
        placement={md ? 'bottomLeft' : undefined}
        destroyTooltipOnHide
        trigger='click'
        open={switcherIsOpen}
        onOpenChange={setSwitcherIsOpen}
        showArrow={false}
        overlayInnerStyle={{
          maxHeight: '80vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {md ? (
          <Breadcrumb items={breadcrumbs.map(title => ({ title }))} />
        ) : (
          <CompactLogo onClick={() => setSwitcherIsOpen(!switcherIsOpen)} />
        )}
      </Popover>
    </Navigation>
  )
})

const Navigation = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;

  .ant-breadcrumb {
    cursor: pointer;
    padding: 4px;
    width: fit-content;

    color: black;
    opacity: 0.7;

    :hover,
    &.ant-popover-open {
      opacity: 1;
    }

    ol {
      flex-wrap: nowrap !important;
    }

    .ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.45);
    }

    li,
    .ant-breadcrumb-link {
      font-size: 18px;
      line-height: 18px;
      color: inherit !important;
      white-space: nowrap;
    }
  }
`

export default NavigationBreadcrumbs
