import { AxiosError } from 'axios'
import { action, makeObservable, observable } from 'mobx'
import moment from 'moment'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import {
  LightDBStreamFilters,
  LightDBStreamQuery,
  LightDBStreamResponse,
} from '../interfaces/ILightDBStream'
import { fetchLightDBStream } from '../shared/serverApi/lightDBStreamApi'

export class LightDBStore {
  @observable isLoading = false

  @observable selectedQueryId =
    localStorage.getItem('golioth.current.projectId') || undefined

  @observable page = 0
  @observable perPage = 10
  @observable total = 1
  @observable query?: LightDBStreamQuery
  @observable filters: LightDBStreamFilters = {
    start: () => moment().add(-4, 'h').toISOString(),
    end: () => moment().toISOString(),
  }

  @observable queryResponse: LightDBStreamResponse[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setPagination(page: number, pageSize = 10, total?: number) {
    const newTotal = total ?? this.total
    const lastPage = Math.max(Math.ceil(newTotal / pageSize - 1), 0)
    const newPage = page > lastPage ? lastPage : page

    this.page = newPage
    this.perPage = pageSize
    this.total = newTotal
  }

  @action.bound
  setFilters(filters: LightDBStreamFilters) {
    this.page = 0
    this.filters = filters
  }

  @action.bound
  setQuery(query: LightDBStreamQuery) {
    this.page = 0
    this.query = query
  }

  @action.bound
  setSelectedQueryId(projectId: string) {
    this.selectedQueryId = projectId
  }

  @action.bound
  async loadLightDBStream(silentLoading?: boolean) {
    try {
      if (!silentLoading) this.isLoading = true
      const {
        page,
        perPage,
        query,
        filters,
        selectedQueryId,
        localStoreQuery,
        localStoreFilters,
      } = this
      if (!query || !selectedQueryId) return
      const response = await fetchLightDBStream(query, page, perPage, {
        ...filters,
        start:
          typeof filters?.start === 'function'
            ? filters?.start()
            : filters?.start,
        end: typeof filters?.end === 'function' ? filters?.end() : filters?.end,
      })

      localStoreQuery(selectedQueryId, query)
      localStoreFilters(selectedQueryId, filters)
      this.queryResponse = response.list

      this.setPagination(response.page, response.perPage, response.total)

      return response.list
    } catch (e) {
      DefaultErrorNotification(e as AxiosError)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  localStoreQuery(projectId: string, query: LightDBStreamQuery) {
    localStorage.setItem(
      `golioth.lightDB.query.${projectId}`,
      JSON.stringify(query),
    )
  }

  @action.bound
  recoveryQuery(projectId?: string) {
    if (!projectId) return defaultQuery
    this.setSelectedQueryId(projectId)

    // Avoid breaking change by using old query storage structure.
    const oldQuery = localStorage.getItem('golioth.lightDB.query')
    const oldQueryParsed = oldQuery ? JSON.parse(oldQuery) : undefined

    const rawQuery = localStorage.getItem(`golioth.lightDB.query.${projectId}`)
    const parsedQuery = rawQuery ? JSON.parse(rawQuery) : undefined
    if (parsedQuery) return parsedQuery as LightDBStreamQuery

    if (oldQuery) {
      // Remove old structure for no future issues.
      localStorage.removeItem('golioth.lightDB.query')
      return oldQueryParsed as LightDBStreamQuery
    }
    return defaultQuery
  }

  @action.bound
  localStoreFilters(projectId: string, filters?: LightDBStreamFilters) {
    if (!filters || Object.keys(filters).length === 0)
      return localStorage.removeItem(`golioth.lightDB.filters.${projectId}`)

    localStorage.setItem(
      `golioth.lightDB.filters.${projectId}`,
      JSON.stringify(filters),
    )
  }

  @action.bound
  recoveryFilters(projectId?: string) {
    if (!projectId) return defaultFilters
    this.setSelectedQueryId(projectId)

    // Avoid breaking change by using old filters storage structure.
    const oldFilters = localStorage.getItem('golioth.lightDB.filters')
    const oldFiltersParsed = oldFilters ? JSON.parse(oldFilters) : undefined

    const rawFilters = localStorage.getItem(
      `golioth.lightDB.filters.${projectId}`,
    )
    const parsedFilters = rawFilters ? JSON.parse(rawFilters) : undefined
    if (parsedFilters && Object.keys(parsedFilters).length > 0)
      return parsedFilters as LightDBStreamFilters

    if (oldFilters) {
      // Remove old structure for no future issues.
      localStorage.removeItem('golioth.lightDB.filters')
      return oldFiltersParsed as LightDBStreamFilters
    }
    return defaultFilters
  }
}

export const defaultQuery: LightDBStreamQuery = {
  fields: [
    {
      path: 'time',
      type: '',
    },
    {
      path: 'deviceId',
      type: '',
    },
    {
      path: '*',
      type: '',
    },
  ],
  filters: [],
}

export const defaultFilters = {
  deviceId: undefined,
  start: () => moment().add(-4, 'h').toISOString(),
  end: () => moment().toISOString(),
  tags: undefined,
}
