import jsonexport from 'jsonexport'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import { Permission, Right } from '../interfaces/IPermission'
import { Policy, PolicyFilters } from '../interfaces/IPolicy'
import { Role } from '../interfaces/IRole'
import { User, UserData } from '../interfaces/IUser'
import {
  deletePolicy,
  fetchPermissions,
  fetchPolicies,
  fetchRoles,
  fetchUsers,
  inviteUser,
  putPolicy,
} from '../shared/serverApi/accessApi'
import { OrgUser } from '../interfaces/IOrgUser'
import { ProjectContextStore } from './ProjectContextStore'
import { ProjectContext } from '../interfaces/IProject'

export class AccessStore extends ProjectContextStore {
  @observable isLoading = false
  @observable isExporting = false
  @observable isBulkRemoving = false
  @observable isLoadingPermissions = false
  @observable isLoadingSelectRoles = false

  @observable policyPage = 0
  @observable policyPerPage = 10
  @observable policyTotal = 1
  @observable policyFilters: PolicyFilters = {
    userId: undefined,
  }

  @observable users: User[] = []
  @observable policies: Policy[] = []
  @observable selectRoles: Role[] = []
  @observable orgUsers: OrgUser[] = []

  @observable currentUserPermissions: Right[] = []

  constructor() {
    super('user permissions')
    makeObservable(this)
  }

  @action.bound
  protected async onContextChange(ctx: ProjectContext | undefined) {
    if (ctx) {
      await this.loadCurrentUserPermissions(ctx.project)
    } else {
      this.clearCurrentUserPermissions()
    }
  }

  @action.bound
  async loadUsers(projectId: string) {
    try {
      this.isLoading = true
      const response = await fetchUsers(projectId)

      this.users = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async loadOrgUsers(projects: string[]) {
    const orgUsers: Record<string, OrgUser> = {}
    try {
      this.isLoading = true

      // Fetch all users from all projects, and organize them as unique Org users:
      await Promise.allSettled(
        projects.map(async projectId => {
          const response = await fetchUsers(projectId)

          response.list.forEach(user => {
            if (!orgUsers[user.id]) {
              orgUsers[user.id] = {
                ...user,
                projects: [],
              }
            }

            orgUsers[user.id].projects.push({
              projectId: projectId,
              policyId: user.policyId,
              roles: user.roles,
            })
          })
        }),
      )

      this.orgUsers = Object.values(orgUsers).sort((a, b) =>
        a.id < b.id ? -1 : 1,
      )

      return this.orgUsers
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async loadPolicies(page?: number, perPage?: number, filters?: PolicyFilters) {
    try {
      this.isLoading = true
      const response = await fetchPolicies(page, perPage, filters)

      this.policies = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveUser(projectId: string, data: UserData) {
    try {
      this.isLoading = true
      await inviteUser(projectId, data)

      DefaultSuccessMessage('User', 'created', {
        name: data.email,
      })
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeUser(projectId: string, id: string) {
    try {
      this.isLoading = true
      await deletePolicy(projectId, id)

      DefaultSuccessMessage('User', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async updatePolicy(projectId: string, data: Partial<Policy>) {
    try {
      this.isLoading = true
      await putPolicy(projectId, data)

      DefaultSuccessMessage('Role', 'updated')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemovePolicies(projectId: string, ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deletePolicy(projectId, id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} User(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async loadSelectRoles() {
    try {
      this.isLoadingSelectRoles = true
      const response = await fetchRoles()

      this.selectRoles = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoadingSelectRoles = false
    }
  }

  @action.bound
  async exportUsers() {
    try {
      this.isExporting = true
      const csv = await jsonexport(this.users)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'users.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }

  @action.bound
  private async loadCurrentUserPermissions(projectId: string) {
    try {
      this.isLoadingPermissions = true
      const response = await fetchPermissions(projectId)

      // Use this to update ResourceRights at IPermissions.tsx
      // Make sure to be on the project owner user to get all resources
      // mapResourceRights(response.list)

      const permissions = response.list.map<Right>(
        p => `${p.resource}:${p.action}`,
      )

      this.currentUserPermissions = permissions
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isLoadingPermissions = false
    }
  }

  @action.bound
  clearCurrentUserPermissions() {
    this.currentUserPermissions = []
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapResourceRights = (permissions: Permission[]) => {
  const resourceRights = permissions.reduce((acc, p) => {
    const capitalResource =
      p.resource.charAt(0).toUpperCase() + p.resource.slice(1)

    const capitalAction = p.action.charAt(0).toUpperCase() + p.action.slice(1)

    const newAcc = {
      ...acc,
      [capitalResource]: {
        ...acc[capitalResource],
        [capitalAction]: `${p.resource}:${p.action}`,
      },
    }
    return newAcc
  }, {} as { [key: string]: { [key: string]: string } })

  const resources = permissions.reduce((acc, p) => {
    const capitalResource =
      p.resource.charAt(0).toUpperCase() + p.resource.slice(1)
    const newAcc = { ...acc, [capitalResource]: p.resource }
    return newAcc
  }, {} as { [key: string]: string })

  console.log('Resources', resources)
  console.log('ResourceRights', resourceRights)
}
