import { useAuth0 } from '@auth0/auth0-react'
import { observer } from 'mobx-react-lite'
import posthog from 'posthog-js'
import { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { FullScreenLoading } from './components/layout/FullScreenLoading'
import Layout from './components/layout/Layout'
import { Routes } from './components/layout/SwitchRoutes'
import { useStore } from './hooks/useStore'
import { FrigadeTour, useUser, useFlows } from '@frigade/react'
import { projectServerClient, serverClient } from './shared/apiClients'
import { logoutOnUnauthorized } from './shared/axiosConfigUtils'
import { getProjectRouteParams } from './shared/paths'
import { useLogout } from './hooks/useLogout'

const App = observer(() => {
  const { pathname } = useLocation()
  const frigadeTourFlowId = process.env.REACT_APP_PUBLIC_FRIGADE_FLOW_TOUR || ''
  const { pageTitle, wasWizardSkipped } = useStore('layout')
  const title = pageTitle ? `${pageTitle} - Golioth` : 'Golioth Platform'

  const {
    user,
    isAuthenticated,
    isLoading: isLoadingAuth,
    getAccessTokenSilently,
  } = useAuth0()
  const logout = useLogout()

  // Setup logout on unauthorized in the context of the Auth0 provider.
  serverClient.interceptors.response.use(
    undefined,
    logoutOnUnauthorized(logout),
  )
  projectServerClient.interceptors.response.use(
    undefined,
    logoutOnUnauthorized(logout),
  )

  const { setUserIdWithProperties } = useUser()
  const {
    getNumberOfStepsCompleted: getNumberOfStepsCompleted,
    isLoading: isFrigadeLoading,
  } = useFlows()
  const [hasSetFrigadeProps, setHasSetFrigadeProps] = useState<boolean>(false)

  const {
    token,
    isLoading: isLoadingToken,
    retrieveAuthToken,
  } = useStore('auth')

  const { isLoading: isProjectLoading } = useStore('project')
  const currentProjectId = getProjectRouteParams(pathname).project // no route info available here

  const isLoading =
    isLoadingAuth || isLoadingToken || (isAuthenticated && !token)

  const isAuthReady = isAuthenticated && !!token

  async function setFrigadeUser(
    userId: string,
    userEmail: string,
    userName: string,
    isLegacyUser?: boolean,
  ) {
    const userProps = {
      name: userName || '',
      email: userEmail || '',
      saw_legacy_onboarding: isLegacyUser || false,
    }
    if (userId) {
      await setUserIdWithProperties(userId, userProps)
      setHasSetFrigadeProps(true)
    }
  }

  useEffect(() => {
    if (!isAuthenticated || isAuthReady) return
    retrieveAuthToken(getAccessTokenSilently)
  }, [isAuthenticated, isAuthReady])

  useEffect(() => {
    if (!user || !isAuthenticated) return
    posthog.identify(user.sub, {
      name: user.name,
      email: user.email,
    })
  }, [user, isAuthenticated])

  useEffect(() => {
    if (isLoading || isFrigadeLoading || isProjectLoading) return
    const numberOfCompletedTourSteps =
      getNumberOfStepsCompleted(frigadeTourFlowId)
    // check if the user has completed any tour steps
    // if they have started the create project step
    // if they have a project already
    // or they skipped the wizard
    let legacyUser = false
    if (
      (numberOfCompletedTourSteps === 0 && currentProjectId) ||
      wasWizardSkipped
    ) {
      // We use "targeting" in frigade to avoid showing this flow to users
      // based on this legacyUser property (saw_legacy_onboarding in frigade)
      // see https://docs.frigade.com/platform/targeting
      legacyUser = true
    }
    if (user && user.sub && user.email && user.name) {
      setFrigadeUser(user.sub, user.email, user.name, legacyUser)
    }
  }, [currentProjectId, isFrigadeLoading, isProjectLoading, user])

  if (isLoading) return <FullScreenLoading dummyLogin />

  return isAuthReady ? (
    <Layout>
      <Helmet title={title} />
      <Suspense fallback={<FullScreenLoading />}>
        <Routes />
      </Suspense>
      {!isFrigadeLoading && hasSetFrigadeProps ? (
        <FrigadeTour flowId={frigadeTourFlowId} />
      ) : null}
    </Layout>
  ) : (
    <>
      <Helmet title={title} />
      <Suspense fallback={<FullScreenLoading dummyLogin />}>
        <Routes />
      </Suspense>
    </>
  )
})

export default App
