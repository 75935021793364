import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import {
  ProjectCertificate,
  ProjectCertificateData,
} from '../interfaces/IProjectCertificate'
import {
  deleteProjectCertificate,
  fetchProjectCertificate,
  fetchProjectCertificates,
  storeProjectCertificate,
} from '../shared/serverApi/projectCertificateApi'

export class ProjectCertificateStore {
  @observable isLoading = false
  @observable isSelectLoading = false
  @observable isBulkRemoving = false

  @observable page = 0
  @observable perPage = 10
  @observable total = 1

  @observable projectCertificates: ProjectCertificate[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setPagination(page: number, pageSize = 10, total?: number) {
    const newTotal = total ?? this.total
    const lastPage = Math.max(Math.ceil(newTotal / pageSize - 1), 0)
    const newPage = page > lastPage ? lastPage : page

    this.page = newPage
    this.perPage = pageSize
    this.total = newTotal
  }

  @action.bound
  async loadProjectCertificates() {
    try {
      this.isLoading = true
      const { page, perPage } = this
      const response = await fetchProjectCertificates(page, perPage)

      this.projectCertificates = response.list

      this.setPagination(response.page, response.perPage, response.total)

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async loadProjectCertificate(id: string) {
    try {
      this.isLoading = true
      return fetchProjectCertificate(id)
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveProjectCertificate(data: ProjectCertificateData) {
    try {
      this.isLoading = true
      const projectCertificate = await storeProjectCertificate(data)

      DefaultSuccessMessage('Project Certificate', 'created')

      return projectCertificate
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeProjectCertificate(id: string) {
    try {
      this.isLoading = true
      await deleteProjectCertificate(id)

      DefaultSuccessMessage('Project Certificate', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveProjectCertificates(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteProjectCertificate(id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(
          `${successAmount} Project Certificate(s)`,
          'deleted',
        )
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }
}
