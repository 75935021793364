import { ListResponse, Response } from '../../interfaces/IApiResponses'
import {
  DeviceSetting,
  DeviceSettingData,
  DeviceSettingFilters,
} from '../../interfaces/IDeviceSetting'
import { projectServerClient, serverClient } from '../apiClients'

export async function fetchDeviceSettings(filters?: DeviceSettingFilters) {
  try {
    const response = await projectServerClient.get<ListResponse<DeviceSetting>>(
      `/settings`,
      { params: { ...filters } },
    )

    return response.data
  } catch (e) {
    throw e
  }
}

export async function storeDeviceSetting(data: DeviceSettingData) {
  try {
    const dynamicMethod = data.id
      ? projectServerClient.put
      : projectServerClient.post
    const dynamicEndpoint = data.id ? `/settings/${data.id}` : `/settings`
    const response = await dynamicMethod<Response<DeviceSetting>>(
      dynamicEndpoint,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteDeviceSetting(id: string) {
  try {
    await projectServerClient.delete(`/settings/${id}`)
  } catch (e) {
    throw e
  }
}

export async function fetchDeviceSettingsTypes() {
  try {
    const response = await serverClient.get<ListResponse<{ type: string }>>(
      `/settings-data-types`,
    )

    return response.data
  } catch (e) {
    throw e
  }
}
