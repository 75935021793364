import jsonexport from 'jsonexport'
import { action, autorun, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import {
  Blueprint,
  BlueprintData,
  BlueprintFilters,
} from '../interfaces/IBlueprint'
import {
  deleteBlueprint,
  fetchBlueprint,
  fetchBlueprints,
  storeBlueprint,
} from '../shared/serverApi/blueprintApi'
import { Paginated } from './Paginated'
import { ProjectContextStore } from './ProjectContextStore'
import { AsyncCache } from './AsyncCache'

export class BlueprintStore extends ProjectContextStore {
  @observable isLoading = false
  @observable isSelectLoading = false
  @observable isExporting = false
  @observable isRemoving = false
  @observable isBulkRemoving = false

  @observable blueprints = new Paginated<Blueprint, BlueprintFilters>(
    ({ page, perPage, filters }) =>
      fetchBlueprints(this.context, page, perPage, filters),
  )
  @observable selectBlueprints: Blueprint[] = []

  @observable blueprintCache = new AsyncCache(id =>
    fetchBlueprint(this.context, id),
  )

  constructor() {
    super('blueprints')
    makeObservable(this)

    // add all blueprints to cache when updating:
    autorun(() => this.blueprintCache.refresh(this.blueprints))
    autorun(() => this.blueprintCache.refresh(this.selectBlueprints))
  }

  protected onContextChange() {
    this.blueprintCache.reset()
    this.blueprints.reset()
    this.selectBlueprints = []
  }

  @action.bound
  async loadBlueprint(id: string) {
    try {
      this.isLoading = true
      return fetchBlueprint(this.context, id)
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveBlueprint(data: BlueprintData) {
    try {
      this.isLoading = true
      const blueprint = await storeBlueprint(this.context, data)
      const action = data.id ? 'updated' : 'created'

      DefaultSuccessMessage('Blueprint', action, {
        name: data.name,
      })

      return blueprint
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeBlueprint(id: string) {
    try {
      this.isRemoving = true
      await deleteBlueprint(this.context, id)

      DefaultSuccessMessage('Blueprint', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isRemoving = false
    }
  }

  @action.bound
  async bulkRemoveBlueprints(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteBlueprint(this.context, id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Blueprint(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async exportBlueprints() {
    try {
      this.isExporting = true
      const response = await fetchBlueprints(this.context)
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'blueprints.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }

  @action.bound
  async loadSelectBlueprints() {
    try {
      this.isSelectLoading = true
      const response = await fetchBlueprints(this.context)

      this.selectBlueprints = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isSelectLoading = false
    }
  }
}
