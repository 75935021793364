import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import { Board } from '../../interfaces/IBoard'
import { serverClient } from '../apiClients'

export async function fetchBoards(page?: number, perPage?: number) {
  try {
    const response = await serverClient.get<PaginatedListResponse<Board>>(
      `/boards`,
      {
        params: { page, perPage },
      },
    )

    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchBoard(id: string) {
  try {
    const response = await serverClient.get<Response<Board>>(`/boards/${id}`)

    return response.data.data
  } catch (e) {
    throw e
  }
}
