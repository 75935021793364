import { Button, notification } from 'antd'
import axios from 'axios'
import * as React from 'react'
import { history } from '..'
import { ApiErrorDetail, ApiGRPCErrorDetail } from '../interfaces/IApiResponses'
import { generatePath } from 'react-router-dom'
import { getProjectRouteParams, paths } from '../shared/paths'
import { GradientText } from './styled/GradientText'

export const DefaultErrorNotification = (error: unknown) => {
  const { pathname } = history.location
  const { push } = history
  const key = `open${Date.now()}`

  // string
  if (typeof error === 'string')
    return notification.error({
      message: 'Error',
      description: error,
    })

  // AxiosError
  if (axios.isAxiosError(error)) {
    const { request, response } = error
    if (response) {
      const { status, data } = response
      const description = data.details?.map(
        (e: ApiErrorDetail | ApiGRPCErrorDetail) =>
          'fieldViolations' in e ? (
            e.fieldViolations.map((e, i) => (
              <p key={e.field + i}>{`${e.field}: ${e.description}\n`}</p>
            ))
          ) : (
            <p key={e.typeUrl}>
              {e.typeUrl}: {e.value}
            </p>
          ),
      )
      const { org } = getProjectRouteParams(pathname) // get org from path
      let btn: React.ReactNode = null
      if (org && data.message?.includes('quota exceeded')) {
        btn = (
          <Button
            type='default'
            onClick={() => {
              notification.destroy(key)
              push(generatePath(paths.orgs.settings, { org }))
            }}
          >
            <GradientText>Looking for a tier upgrade?</GradientText>
          </Button>
        )
      }

      if (status >= 400)
        return notification.error({
          key,
          btn,
          description,
          message: data.message || 'Error',
        })
    }
    if (request)
      return notification.error({
        message: 'Error',
        description: 'No response found.',
      })
  }
}
