import { AxiosError } from 'axios'
import { action, makeObservable, observable } from 'mobx'
import moment from 'moment'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import {
  LightDBStreamFilters,
  LightDBStreamQuery,
  LightDBStreamResponse,
} from '../interfaces/ILightDBStream'
import { fetchLightDBStream } from '../shared/serverApi/lightDBStreamApi'

export class DeviceLightDBStore {
  @observable isLoading = false

  @observable page = 0
  @observable perPage = 10
  @observable total = 1
  @observable query = defaultQuery
  @observable filters: LightDBStreamFilters = {
    start: () => moment().add(-4, 'h').toISOString(),
    end: () => moment().toISOString(),
  }

  @observable queryResponse: LightDBStreamResponse[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setPagination(page: number, pageSize = 10, total?: number) {
    const newTotal = total ?? this.total
    const lastPage = Math.max(Math.ceil(newTotal / pageSize - 1), 0)
    const newPage = page > lastPage ? lastPage : page

    this.page = newPage
    this.perPage = pageSize
    this.total = newTotal
  }

  @action.bound
  setFilters(filters: LightDBStreamFilters) {
    this.page = 0
    this.filters = filters
  }

  @action.bound
  setQuery(query: LightDBStreamQuery) {
    this.page = 0
    this.query = query
  }

  @action.bound
  async loadLightDBStream(silentLoading?: boolean) {
    try {
      if (!silentLoading) this.isLoading = true
      const { query, page, perPage, filters } = this

      const response = await fetchLightDBStream(query, page, perPage, {
        ...filters,
        start:
          typeof filters?.start === 'function'
            ? filters?.start()
            : filters?.start,
        end: typeof filters?.end === 'function' ? filters?.end() : filters?.end,
      })
      this.queryResponse = response.list

      this.setPagination(response.page, response.perPage, response.total)

      return response.list
    } catch (e) {
      DefaultErrorNotification(e as AxiosError)
      throw e
    } finally {
      this.isLoading = false
    }
  }
}

export const defaultQuery: LightDBStreamQuery = {
  fields: [
    {
      path: 'timestamp',
      type: '',
    },
    {
      path: '*',
      type: '',
    },
  ],
  filters: [],
}
