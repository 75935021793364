import jsonexport from 'jsonexport'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import { Release, ReleaseData, ReleaseFilters } from '../interfaces/IRelease'
import {
  deleteRelease,
  fetchRelease,
  fetchReleases,
  storeRelease,
} from '../shared/serverApi/releaseApi'

export class ReleaseStore {
  @observable isLoading = false
  @observable isSelectLoading = false
  @observable isExporting = false
  @observable isBulkRemoving = false

  @observable page = 0
  @observable perPage = 10
  @observable total = 1
  @observable filters: ReleaseFilters = {
    deviceTags: undefined,
    releaseTags: undefined,
    rollout: undefined,
    blueprintId: undefined,
  }

  @observable release = {} as Release
  @observable releases: Release[] = []
  @observable selectReleases: Release[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setPagination(page: number, pageSize = 10, total?: number) {
    const newTotal = total ?? this.total
    const lastPage = Math.max(Math.ceil(newTotal / pageSize - 1), 0)
    const newPage = page > lastPage ? lastPage : page

    this.page = newPage
    this.perPage = pageSize
    this.total = newTotal
  }

  @action.bound
  setFilters(filters: ReleaseFilters) {
    this.page = 0
    this.filters = filters
  }

  @action.bound
  async loadReleases(silentLoad?: boolean) {
    try {
      if (!silentLoad) this.isLoading = true
      const { page, perPage, filters } = this
      const response = await fetchReleases(page, perPage, filters)

      this.releases = response.list

      this.setPagination(response.page, response.perPage, response.total)

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async loadRelease(id: string) {
    try {
      this.isLoading = true
      const release = await fetchRelease(id)

      this.release = release
      return release
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveRelease(data: ReleaseData, silentLoad?: boolean) {
    try {
      if (!silentLoad) this.isLoading = true
      const release = await storeRelease(data)
      const action = data.id ? 'updated' : 'created'

      this.release = release

      DefaultSuccessMessage('Release', action)

      return release
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeRelease(id: string) {
    try {
      this.isLoading = true
      await deleteRelease(id)
      await this.loadReleases() // TODO: Move to the caller component

      DefaultSuccessMessage('Release', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveReleases(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(ids.map(id => deleteRelease(id)))
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Release(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async exportReleases() {
    try {
      this.isExporting = true
      const response = await fetchReleases()
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'releases.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }

  @action.bound
  async loadSelectReleases() {
    try {
      this.isSelectLoading = true
      const response = await fetchReleases()

      this.selectReleases = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isSelectLoading = false
    }
  }
}
