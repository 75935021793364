import { FunctionComponent } from 'react'
import { LayoutLoader } from './LayoutLoader'

interface FullScreenLoadingProps {
  dummyLogin?: boolean
}

export const FullScreenLoading: FunctionComponent<FullScreenLoadingProps> = ({
  dummyLogin,
}) => {
  return <LayoutLoader id={dummyLogin ? 'login' : 'route'} isLoading tip='' />
}
