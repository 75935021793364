import { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks/useStore'
import { Spin } from 'antd'

interface LoadingPanelProps {
  id: string
  isLoading?: boolean
  tip?: string
}

/**
 * Spinner container for the layout.
 *
 * Split up this way to ensure that we don't unmount the spinner on each child update,
 * as that resets the animation.
 */
export const LayoutLoaderSpinner: FunctionComponent = observer(
  ({ children }) => {
    const { loading } = useStore('layout')

    const isLoading = Object.values(loading).find(v => !!v)

    return (
      <>
        {children}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '70vh',
            display: isLoading ? 'flex' : 'none',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          <div style={{ width: '100%', zoom: '1.3' }}>
            <Spin
              size='large'
              spinning
              tip={typeof isLoading === 'string' ? isLoading : undefined}
            >
              <div />
            </Spin>
          </div>
        </div>
      </>
    )
  },
)

/**
 * Dummy panel with a spinner to show instead of real content while loading.
 *
 * Nested loading panels will appear as a single spinner.
 */
export const LayoutLoader = observer(
  ({ id, tip, isLoading, children }: PropsWithChildren<LoadingPanelProps>) => {
    const { setLoading } = useStore('layout')
    /* Don't mount real content until we finish loading.
     * This ensures that anything that relies on the data
     * being loaded will not start fetching stuff until
     * the data is actually available.
     */

    useEffect(() => {
      if (isLoading) {
        setLoading(id, tip ?? `Loading ${id}...`)
      } else {
        setLoading(id, false)
      }
    }, [isLoading, tip])

    useEffect(() => {
      return () => setLoading(id, false) // on unmount
    }, [])

    if (isLoading) {
      // spacer to keep the layout from jumping around
      return (
        <div
          data-testid='layout-loader'
          style={{ width: '100%', height: '50vh' }}
        />
      )
    }

    return <>{children}</>
  },
)
