import Rive from '@rive-app/react-canvas-lite'
import animation from '../assets/animations/echoTyping.riv'

export function EchoAnimation() {
  return (
    <div style={{ height: 288, width: 272 }}>
      <Rive src={animation} width='100%' height='100%'></Rive>
    </div>
  )
}
