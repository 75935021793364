import { Layout } from 'antd'
import styled from 'styled-components'
import background from '../../assets/images/golioth-background.svg'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { FunctionComponent } from 'react'

// Use the same background as auth0 does on the login page:

export const SplashScreenBackground: FunctionComponent = ({ children }) => {
  const { md } = useBreakpoint()

  return (
    <Styled style={{ backgroundPositionY: md ? undefined : '25vh' }}>
      {children}
    </Styled>
  )
}

export const Styled = styled(Layout)`
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-color: #fafafa;

  // Fill entire screen, even when it's part of the layout:
  position: fixed;
  inset: 0;
`
