import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import {
  OutputStream,
  OutputStreamData,
  OutputStreamFilters,
  OutputStreamType,
} from '../../interfaces/IOutputStream'
import { projectServerClient, serverClient } from '../apiClients'

export async function fetchOutputStreamTypes(page?: number, perPage?: number) {
  try {
    const response = await serverClient.get<
      PaginatedListResponse<OutputStreamType>
    >(`integration-types`, {
      params: { page, perPage },
    })
    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchOutputStreams(
  page?: number,
  perPage?: number,
  filters?: OutputStreamFilters,
) {
  try {
    const response = await projectServerClient.get<
      PaginatedListResponse<OutputStream>
    >(`/integrations`, {
      params: { page, perPage, ...filters },
    })

    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchOutputStream(id: string) {
  try {
    const response = await projectServerClient.get<Response<OutputStream>>(
      `/integrations/${id}`,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function validateOutputStream(data: OutputStreamData) {
  try {
    const response = await projectServerClient.post<Response<OutputStream>>(
      `/integrations/validate`,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function storeOutputStream(data: OutputStreamData) {
  try {
    const dynamicMethod = data.id
      ? projectServerClient.put
      : projectServerClient.post

    const dynamicEndpoint = data.id
      ? `/integrations/${data.id}`
      : `/integrations`

    const response = await dynamicMethod<Response<OutputStream>>(
      dynamicEndpoint,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteOutputStream(id: string) {
  try {
    await projectServerClient.delete(`/integrations/${id}`)
  } catch (e) {
    throw e
  }
}

export async function testOutputStream(
  id: string,
  data: Record<string, unknown>,
) {
  try {
    const response = await projectServerClient.post(
      `/integrations/${id}/test`,
      {
        data: data,
      },
    )

    return response.data
  } catch (e) {
    throw e
  }
}
