import { action, autorun, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { Board } from '../interfaces/IBoard'
import { fetchBoard, fetchBoards } from '../shared/serverApi/boardApi'
import { AsyncCache } from './AsyncCache'

export class BoardStore {
  @observable isLoading = false
  @observable isSelectLoading = false

  @observable board = {} as Board
  @observable boards: Board[] = []
  @observable selectBoards: Board[] = []

  @observable allBoards = new AsyncCache(id => fetchBoard(id))

  constructor() {
    makeObservable(this)
    autorun(() => this.allBoards.refresh(this.boards))
    autorun(() => this.allBoards.refresh(this.selectBoards))
  }

  @action.bound
  async loadSelectBoards() {
    try {
      this.isSelectLoading = true
      const response = await fetchBoards()

      this.selectBoards = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isSelectLoading = false
    }
  }

  @action.bound
  async loadBoard(id: string) {
    try {
      this.isLoading = true
      const board = await fetchBoard(id)

      this.board = board
      return board
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }
}
