import { observer } from 'mobx-react-lite'
import { DismissableBanner } from './DismissableBanner'
import { useStore } from '../hooks/useStore'
import { Pipelines } from '../interfaces/IPermission'
import { Button, Row, Space } from 'antd'
import { VerticalSpace } from './custom-antd/VerticalSpace'
import { DocumentationLink } from './DocumentationLink'
import { useAuth0 } from '@auth0/auth0-react'

/** Generate opt in link with parameters */
function optInLink(params: { projectId?: string; email?: string }) {
  const url = 'https://hslp.golioth.io/pipelines-opt-in'
  const query = new URLSearchParams()
  if (params.projectId) {
    query.set('project_id', params.projectId)
  }

  if (params.email) {
    query.set('email', params.email)
  }

  if (query.toString()) {
    return `${url}?${query}`
  }

  return url
}

export const PipelineBanner = observer(() => {
  const { currentUserPermissions } = useStore('access')
  const { project } = useStore('project')
  const { user } = useAuth0()

  if (
    !currentUserPermissions ||
    // Hide for projects that have already been migrated:
    currentUserPermissions.includes(Pipelines.Read)
  ) {
    return null
  }

  const optIn = optInLink({ projectId: project?.id, email: user?.email })

  return (
    <DismissableBanner id='pipelines-migration'>
      <Row
        align='middle'
        justify='space-between'
        style={{ width: '100%' }}
        wrap={false}
      >
        <VerticalSpace>
          <h1>Pipelines Are Here! 🎉</h1>
          <div>
            Pipelines are an evolution of Output Streams, providing a new, more
            powerful way to manage device data routing.
            <br />
            To migrate your existing projects to Pipelines, you can opt-in
            through{' '}
            <a href={optIn} target='#'>
              this form
            </a>
            .
          </div>
        </VerticalSpace>
        <Space>
          <DocumentationLink type='default' path='data-routing'>
            Read More
          </DocumentationLink>
          <Button href={optIn} type='primary' target='#'>
            Migrate Now
          </Button>
        </Space>
      </Row>
    </DismissableBanner>
  )
})
