import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import { ProjectContext } from '../../interfaces/IProject'
import { Tag, TagData, TagFilters } from '../../interfaces/ITag'
import { serverClient } from '../apiClients'

function url({ project }: ProjectContext, subPage?: string) {
  const base = `/projects/${project}/tags`

  return subPage ? `${base}/${subPage}` : base
}

export async function fetchTags(
  ctx: ProjectContext,
  page?: number,
  perPage?: number,
  filters?: TagFilters,
) {
  const response = await serverClient.get<PaginatedListResponse<Tag>>(
    url(ctx),
    {
      params: { page, perPage, ...filters },
    },
  )

  return response.data
}

export async function fetchTag(ctx: ProjectContext, id: string) {
  const response = await serverClient.get<Response<Tag>>(url(ctx, id))

  return response.data.data
}

export async function storeTag(ctx: ProjectContext, data: TagData) {
  if (data.id) {
    const response = await serverClient.put<Response<Tag>>(
      url(ctx, data.id),
      data,
    )

    return response.data.data
  } else {
    const response = await serverClient.post<Response<Tag>>(url(ctx), data)

    return response.data.data
  }
}

export async function deleteTag(ctx: ProjectContext, id: string) {
  await serverClient.delete(url(ctx, id))
}
