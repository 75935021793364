import { lazy } from 'react'
import { RouteProps } from 'react-router-dom'
import { isFeatureActive } from './feature-flags'
import {
  Apikeys,
  Artifacts,
  Blueprints,
  Certificates,
  Devices,
  Devicestream,
  Integrations,
  Logs,
  Pipelines,
  Releases,
  Right,
  Secrets,
  Settings,
  Tags,
} from './interfaces/IPermission'
import { ProjectLayout } from './components/layout/ProjectLayout'
import { OrgLayout } from './components/layout/OrgLayout'

export enum AccessPolice {
  Guest,
  Auth,
}

export interface ResolvedRoute extends Omit<RouteProps, 'path'> {
  routes?: Record<string, ResolvedRoute>
  hideRoute?: boolean
  accessPolice?: AccessPolice
  requiredPermissions?: (Right | undefined)[]
  layout?: React.FunctionComponent
}

export const routes = {
  '/': {
    exact: true,
    accessPolice: AccessPolice.Auth,
    component: lazy(() => import('./views/Index')),
  },

  // org
  '/org/:org': {
    accessPolice: AccessPolice.Auth,
    component: lazy(() => import('./views/projects/ProjectIndex')),
    layout: OrgLayout,
    routes: {
      '/settings/:tab?': {
        accessPolice: AccessPolice.Auth,
        component: lazy(
          () =>
            import('./views/organization-settings/OrganizationSettingsIndex'),
        ),
      },
      '/edit': {
        accessPolice: AccessPolice.Auth,
        component: lazy(() => import('./views/organizations/OrganizationEdit')),
      },
      '/projects': {
        accessPolice: AccessPolice.Auth,
        component: lazy(() => import('./views/projects/ProjectIndex')),
        routes: {
          '/create': {
            accessPolice: AccessPolice.Auth,
            component: lazy(() => import('./views/projects/ProjectCreate')),
          },
        },
      },
      '/project/:project': {
        accessPolice: AccessPolice.Auth,
        requiredPermissions: [Devices.Read],
        layout: ProjectLayout,
        component: lazy(() => import('./views/devices/DeviceIndex')),
        routes: {
          '/edit': {
            accessPolice: AccessPolice.Auth,
            component: lazy(() => import('./views/projects/ProjectEdit')),
          },
          '/devices': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Devices.Read],
            component: lazy(() => import('./views/devices/DeviceIndex')),
            routes: {
              '/create': {
                accessPolice: AccessPolice.Auth,
                requiredPermissions: [Devices.Write],
                component: lazy(() => import('./views/devices/DeviceCreate')),
              },
            },
          },
          '/device/:device': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Devices.Read],
            component: lazy(() => import('./views/devices/DeviceManagement')),
            routes: {
              '/edit': {
                accessPolice: AccessPolice.Auth,
                requiredPermissions: [Devices.Write],
                component: lazy(() => import('./views/devices/DeviceEdit')),
              },
              '/:tab?': {
                accessPolice: AccessPolice.Auth,
                requiredPermissions: [Devices.Read],
                component: lazy(
                  () => import('./views/devices/DeviceManagement'),
                ),
              },
            },
          },
          '/tags': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Tags.Read],
            component: lazy(() => import('./views/tags/TagIndex')),
          },
          '/blueprints': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Blueprints.Read],
            component: lazy(() => import('./views/blueprints/BlueprintIndex')),
          },
          '/blueprint/:blueprint': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Blueprints.Read],
            component: lazy(
              () => import('./views/blueprints/BlueprintManagement'),
            ),
          },
          '/logs': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Logs.Read],
            component: lazy(() => import('./views/logs/LogIndex')),
          },
          '/artifacts': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Artifacts.Read],
            component: lazy(() => import('./views/artifacts/ArtifactsIndex')),
            routes: {
              '/create': {
                accessPolice: AccessPolice.Auth,
                requiredPermissions: [Artifacts.Write],
                component: lazy(
                  () => import('./views/artifacts/ArtifactCreate'),
                ),
              },
            },
          },
          '/releases': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Releases.Read],
            component: lazy(() => import('./views/releases/ReleasesIndex')),
            routes: {
              '/create': {
                requiredPermissions: [Releases.Write],
                accessPolice: AccessPolice.Auth,
                component: lazy(() => import('./views/releases/ReleaseCreate')),
              },
            },
          },
          '/release/:release': {
            requiredPermissions: [Releases.Write],
            accessPolice: AccessPolice.Auth,
            component: lazy(() => import('./views/releases/ReleaseEdit')),
          },
          '/lightdb-stream': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Devicestream.Read],
            component: lazy(
              () => import('./views/lightdb-stream/LightDBStreamIndex'),
            ),
          },
          '/device-settings': {
            hideRoute: !isFeatureActive('device-settings'),
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Settings.Read],
            component: lazy(
              () => import('./views/device-settings/DeviceSettingIndex'),
            ),
          },
          '/pipelines': {
            hideRoute: !isFeatureActive('pipelines'),
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Pipelines.Read],
            component: lazy(() => import('./views/pipelines/PipelineIndex')),
            routes: {
              '/create': {
                hideRoute: !isFeatureActive('pipelines'),
                accessPolice: AccessPolice.Auth,
                requiredPermissions: [Pipelines.Write],
                component: lazy(
                  () => import('./views/pipelines/PipelineCreate'),
                ),
              },
            },
          },
          '/pipeline/:pipeline': {
            hideRoute: !isFeatureActive('pipelines'),
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Pipelines.Read],
            component: lazy(() => import('./views/pipelines/PipelineEdit')),
          },
          '/apikeys': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Apikeys.Read],
            component: lazy(() => import('./views/apikeys/ApiKeyIndex')),
          },
          '/certificates': {
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Certificates.Read],
            component: lazy(
              () => import('./views/project-certificates/CertificateIndex'),
            ),
          },
          '/secrets': {
            hideRoute: !isFeatureActive('pipelines'),
            accessPolice: AccessPolice.Auth,
            requiredPermissions: [Secrets.Read],
            component: lazy(() => import('./views/secrets/SecretsIndex')),
          },
          '/settings': {
            accessPolice: AccessPolice.Auth,
            component: lazy(
              () => import('./views/project-settings/ProjectSettingsIndex'),
            ),
            routes: {
              '/:tab?': {
                exact: true,
                accessPolice: AccessPolice.Auth,
                component: lazy(
                  () => import('./views/project-settings/ProjectSettingsIndex'),
                ),
              },
              '/output-streams': {
                accessPolice: AccessPolice.Auth,
                requiredPermissions: [Integrations.Read],
                routes: {
                  '/select-type': {
                    accessPolice: AccessPolice.Auth,
                    requiredPermissions: [Integrations.Write],
                    component: lazy(
                      () =>
                        import(
                          './views/project-settings/components/output-streams/OutputStreamTypeSelect'
                        ),
                    ),
                  },
                  '/:typeId/create': {
                    accessPolice: AccessPolice.Auth,
                    requiredPermissions: [Integrations.Write],
                    component: lazy(
                      () =>
                        import(
                          './views/project-settings/components/output-streams/OutputStreamCreate'
                        ),
                    ),
                  },
                },
              },
              '/output-stream/:stream': {
                accessPolice: AccessPolice.Auth,
                requiredPermissions: [Integrations.Read],
                component: lazy(
                  () =>
                    import(
                      './views/project-settings/components/output-streams/OutputStreamDetails'
                    ),
                ),
                routes: {
                  '/edit': {
                    accessPolice: AccessPolice.Auth,
                    requiredPermissions: [Integrations.Write],
                    component: lazy(
                      () =>
                        import(
                          './views/project-settings/components/output-streams/OutputStreamEdit'
                        ),
                    ),
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  '/orgs/create': {
    accessPolice: AccessPolice.Auth,
    component: lazy(() => import('./views/organizations/OrganizationCreate')),
  },
  '/login': {
    accessPolice: AccessPolice.Guest,
    component: lazy(() => import('./views/login/Login')),
  },
  '/profiling-questions': {
    accessPolice: AccessPolice.Guest,
    component: lazy(
      () => import('./views/profiling-questions/ProfilingQuestions'),
    ),
  },

  // Shareable paths:
  '/devices': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/blueprints': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/tags': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/project-settings': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/device-settings': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/artifacts': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/output-streams': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/releases': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/logs': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/lightdb-stream': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/organization-settings': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/projects': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/pipelines': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/pipeline': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },
  '/usage': {
    component: lazy(() => import('./views/legacy-redirect/LegacyRedirect')),
  },

  '*': {
    accessPolice: AccessPolice.Guest,
    component: lazy(() => import('./views/not-found/NotFound')),
  },
}

type Routes = Record<string, RouteProps | unknown>

type DeepRoutes<
  T extends Routes,
  Prefix extends string = '',
> = keyof T extends string
  ?
      | `${Prefix}${{
          [K in keyof T]: T[K] extends { routes: Routes }
            ? K | DeepRoutes<T[K]['routes'], K>
            : K
        }[keyof T]}`
  : never

export type AppRoute = DeepRoutes<typeof routes>
