import { OAuthError, useAuth0 } from '@auth0/auth0-react'
import { useMemo } from 'react'
import { Auth0User } from '../interfaces/IAuth0User'

function getGoliothErrorType(error: Error): string | undefined {
  // https://github.com/golioth/golioth-web/pull/393#issuecomment-2043420369
  try {
    return JSON.parse((error as OAuthError).error_description ?? '{}').error
  } catch {
    return undefined
  }
}

/** Get whether the user's email has been verified */
export function useIsEmailVerified() {
  const { error } = useAuth0<Auth0User>()

  return useMemo(() => {
    return !(error && getGoliothErrorType(error) === 'unverified_email')
  }, [error])
}
