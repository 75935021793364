import jsonexport from 'jsonexport'
import { action, makeObservable, observable } from 'mobx'
import moment from 'moment'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { Log, LogFilters } from '../interfaces/ILog'
import { fetchLogs } from '../shared/serverApi/logApi'

export class LogStore {
  @observable isLoading = false
  @observable isExporting = false

  @observable page = 0
  @observable perPage = 10
  @observable total = 1
  @observable filters: LogFilters = {
    level: undefined,
    deviceId: undefined,
    start: () => moment().add(-4, 'h').toISOString(),
    end: () => moment().toISOString(),
    module: undefined,
    term: undefined,
    tags: undefined,
  }

  @observable logs: Log[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setPagination(page: number, pageSize?: number, total?: number) {
    const newTotal = total ?? this.total
    const lastPage = Math.max(Math.ceil(newTotal / (pageSize || 10) - 1), 0)
    const newPage = page > lastPage ? lastPage : page

    this.page = newPage
    this.total = newTotal
    if (pageSize) this.perPage = pageSize
  }

  @action.bound
  setFilters(filters: LogFilters) {
    this.page = 0
    this.filters = filters
  }

  @action.bound
  async loadLogs(silentLoading?: boolean) {
    try {
      if (!silentLoading) this.isLoading = true
      const { filters } = this
      const response = await fetchLogs(this.page, this.perPage, {
        ...filters,
        start:
          typeof filters.start === 'function' ? filters.start() : filters.start,
        end: typeof filters.end === 'function' ? filters.end() : filters.end,
      })

      this.logs = response.list

      this.setPagination(response.page, undefined, response.total)

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async exportLogs() {
    try {
      this.isExporting = true
      const { page, perPage, filters } = this
      const response = await fetchLogs(page, perPage, {
        ...filters,
        start:
          typeof filters.start === 'function' ? filters.start() : filters.start,
        end: typeof filters.end === 'function' ? filters.end() : filters.end,
      })
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'logs.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.isExporting = false
    }
  }
}
