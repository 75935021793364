import { action, computed, makeObservable, observable } from 'mobx'
import { PaginatedListResponse } from '../interfaces/IApiResponses'
import { TablePaginationConfig } from 'antd'

interface RequestParams<Filters> {
  page: number
  perPage: number
  filters?: Filters
}

interface Options {
  /** Number of items per page, or DEFAULT_PAGE_SIZE */
  initialPageSize: number
  /** Whether to show the page size selector */
  canChangePageSize: boolean
}

const DEFAULT_PAGE_SIZE = 10

/** Store for a paginated list of items.
 *
 * This store can be embedded in another store to manage a paginated list of items.
 * This abstracts a common pattern of keeping a list along with a page number and total count.
 */
export class Paginated<Item, Filters = undefined> {
  /** Items, or null if no items have been loaded yet. */
  @observable list: Item[] | null = null

  /* Zero-indexed page number */
  @observable page = 0
  @observable perPage: number
  @observable private options: Options

  /* Total number of items, as reported by the load call */
  @observable total = 1
  @observable filters?: Filters
  @observable isLoading = false

  /**
   * Create a paginated store.
   * @param loader Callback to load items
   * @param filters Default value for filters. Can be changed with setFilters.
   */
  constructor(
    private readonly loader: (
      request: RequestParams<Filters>,
    ) => Promise<PaginatedListResponse<Item>>,
    filters?: Filters,
    options?: Partial<Options>,
  ) {
    this.filters = filters
    this.options = {
      initialPageSize: DEFAULT_PAGE_SIZE,
      canChangePageSize: false,
      ...options,
    }
    this.perPage = this.options.initialPageSize
    makeObservable(this)
  }

  /** No items yet, but we're loading */
  @computed
  get isInitialLoad() {
    return this.list === null && this.isLoading
  }

  /** Items were loaded already, but we're currently refreshing */
  @computed
  get isReloading() {
    return this.list !== null && this.isLoading
  }

  @action.bound
  reset() {
    this.list = null
    this.page = 0
    this.perPage = this.options.initialPageSize
    this.total = 1
  }

  @action.bound
  setFilters(filters: Filters | undefined) {
    this.page = 0
    this.total = 1

    this.filters = filters
    this.load()
  }

  @action.bound
  clearFilters() {
    this.setFilters(undefined)
  }

  @action.bound
  setPagination(page: number, pageSize?: number, total?: number) {
    pageSize ??= this.options.initialPageSize

    const newTotal = total ?? this.total
    const finalPage = Math.max(Math.ceil(newTotal / pageSize - 1), 0)
    const newPage = page > finalPage ? finalPage : page

    this.page = newPage
    this.perPage = pageSize
    this.total = newTotal
  }

  @action.bound
  private handle(rsp: PaginatedListResponse<Item>) {
    this.list = rsp.list

    this.setPagination(rsp.page, rsp.perPage, rsp.total)
  }

  @action.bound
  private setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  @action.bound
  async load() {
    try {
      this.setIsLoading(true)

      const result = await this.loader({
        page: this.page,
        perPage: this.perPage,
        filters: this.filters!,
      })

      this.handle(result)

      return result.list
    } finally {
      this.setIsLoading(false)
    }
  }

  @computed
  get tablePaginationConfig(): TablePaginationConfig {
    return {
      current: this.page + 1,
      pageSize: this.perPage,
      showSizeChanger: this.options.canChangePageSize,
      total: this.total,
    }
  }

  @action.bound
  handleTableChange(pagination: TablePaginationConfig) {
    const { current, pageSize } = pagination

    const newPage = current ? current - 1 : 0
    const newPageSize = pageSize || this.options.initialPageSize

    this.setPagination(newPage, newPageSize)
    this.load()
  }
}
