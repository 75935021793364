import jsonexport from 'jsonexport'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import {
  DeviceSetting,
  DeviceSettingData,
  DeviceSettingFilters,
} from '../interfaces/IDeviceSetting'
import {
  deleteDeviceSetting,
  fetchDeviceSettings,
  storeDeviceSetting,
} from '../shared/serverApi/deviceSettingsApi'

export class DeviceSettingStore {
  @observable isLoading = false
  @observable isSelectLoading = false
  @observable isExporting = false
  @observable isBulkRemoving = false

  @observable deviceSetting = {} as DeviceSetting
  @observable deviceSettings: DeviceSetting[] = []
  @observable selectDeviceSettings: DeviceSetting[] = []

  constructor() {
    makeObservable(this)
  }

  @action.bound
  async loadDeviceSettings(filters?: DeviceSettingFilters) {
    try {
      this.isLoading = true
      const response = await fetchDeviceSettings(filters)

      this.deviceSettings = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveDeviceSetting(data: DeviceSettingData) {
    try {
      this.isLoading = true
      const deviceSetting = await storeDeviceSetting(data)
      const action = data.id ? 'updated' : 'created'
      this.deviceSetting = deviceSetting

      DefaultSuccessMessage('DeviceSetting', action, {
        name: data.key,
      })
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeDeviceSetting(id: string) {
    try {
      this.isLoading = true
      await deleteDeviceSetting(id)

      DefaultSuccessMessage('DeviceSetting', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveDeviceSettings(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteDeviceSetting(id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} DeviceSetting(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async exportDeviceSettings() {
    try {
      this.isExporting = true
      const response = await fetchDeviceSettings()
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'deviceSettings.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }

  @action.bound
  async loadSelectDeviceSettings() {
    try {
      this.isSelectLoading = true
      const response = await fetchDeviceSettings()

      this.selectDeviceSettings = response.list

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isSelectLoading = false
    }
  }
}
