import { CodeOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import React, { FC, createRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useStore } from '../hooks/useStore'
import { when } from 'mobx'
import { observer } from 'mobx-react-lite'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

const StyledFrame = styled.iframe`
  height: 600px;
  max-height: 80vh;
  width: 500px;
  max-width: 80vw;
  border: none !important;
`

interface SetCredentialsMessage {
  type: 'setCredentials'
  id: string
  psk: string
}

const simContext =
  React.createContext<React.RefObject<HTMLIFrameElement> | null>(null)

const deviceSimUrl = process.env.REACT_APP_DEVICE_SIMULATOR_URL

export function SimulatorContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <simContext.Provider value={createRef<HTMLIFrameElement>()}>
      {children}
    </simContext.Provider>
  )
}

export function useSimulator() {
  /* Note: We can't destructure the store here, as it would dereference
   * the isSimulatorReady observable, which we cannot do outside of
   * the mobx when() callback, as that is how it knows to track the dependency.
   */
  const layout = useStore('layout')
  const simRef = useContext(simContext)

  return async (id: string, psk: string) => {
    if (!deviceSimUrl) {
      return
    }

    layout.setSimulatorOpen(true)

    /* Simulator needs to load and open first. Waiting for
     * the ref is not enough, as the simulator has to set
     * up its event listeners before we can send anything.
     */
    await when(() => layout.isSimulatorReady)

    simRef?.current?.contentWindow?.postMessage(
      { type: 'setCredentials', id, psk } as SetCredentialsMessage,
      deviceSimUrl,
    )
  }
}

export const DeviceSimulator: FC = observer(() => {
  const simRef = useContext(simContext)
  const breakpoints = useBreakpoint()
  const { isSimulatorOpen, setSimulatorOpen, setSimulatorReady } =
    useStore('layout')

  /* Event handler */
  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.origin !== deviceSimUrl) {
        return
      }

      switch (e.data) {
        case 'ready':
          // Now we can send messages to the simulator
          setSimulatorReady(true)
          break
        case 'close':
          setSimulatorOpen(false)
          break
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
      setSimulatorReady(false)
    }
  }, [])

  /* Close on Escape (only works outside the sim) */
  useEffect(() => {
    if (isSimulatorOpen) {
      const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setSimulatorOpen(false)
          e.preventDefault()
          e.stopPropagation()
        }
      }
      window.addEventListener('keydown', handleKeydown)
      return () => window.removeEventListener('keydown', handleKeydown)
    }
  }, [isSimulatorOpen])

  if (!deviceSimUrl) {
    return null
  }

  return (
    <Popover
      trigger={['click']}
      open={isSimulatorOpen}
      align={{ offset: [-20, -5] }}
      overlayClassName='simulator-container'
      content={<StyledFrame src={deviceSimUrl} ref={simRef} />}
    >
      <Button
        type='text'
        icon={<CodeOutlined />}
        onClick={() => setSimulatorOpen(!isSimulatorOpen)}
      >
        {breakpoints.md && 'Simulator'}
      </Button>
    </Popover>
  )
})
