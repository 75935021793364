import { action, computed, makeObservable, observable } from 'mobx'
import { Paginated } from './Paginated'

type WithId<Item> = Item & { id: string }

const DEFAULT_MAX_SIZE = 1024

/**
 * Store for caching asynchronous data.
 *
 * Any parallel loads to the same resource will be deduplicated.
 */
export class AsyncCache<Item, LoaderResult = Item> {
  @observable private map = new Map<string, Promise<Item>>()

  readonly maxCount: number
  private readonly getValue?: (item: LoaderResult) => Item

  /**
   * Create a new cache.
   *
   * @param loader Loader function that returns a promise.
   * @param maxCount Maximum number of items to store in the cache.
   */
  constructor(loader: (id: string) => Promise<Item>, maxCount?: number)
  /**
   * Create a new cache with a getValue callback.
   *
   * The getValue is used to convert the result of the loader function to the item type.
   *
   * @param loader Loader function that returns a promise.
   * @param getValue getValue function that converts the result of the loader to the stored item type.
   * @param maxCount Maximum number of items to store in the cache.
   */
  constructor(
    loader: (id: string) => Promise<LoaderResult>,
    getValue?: (item: LoaderResult) => Item,
    maxCount?: number,
  )
  constructor(
    private readonly loader: (id: string) => Promise<LoaderResult | Item>,
    converterOrMaxCount?: number | ((item: LoaderResult) => Item),
    maxCount?: number,
  ) {
    makeObservable(this)

    if (typeof converterOrMaxCount === 'function') {
      this.getValue = converterOrMaxCount
      this.maxCount = maxCount || DEFAULT_MAX_SIZE
    } else {
      this.maxCount = converterOrMaxCount || DEFAULT_MAX_SIZE
    }
  }

  @action.bound
  private clearExcessItems() {
    // Delete oldest entries. Map key order is guaranteed to be the insertion order:
    while (this.map.size > this.maxCount) {
      // Restart iterator to get the first key:
      // Preferable to plopping the whole thing into an array, as we avoid a memory allocation.
      this.map.delete(this.map.keys().next().value)
    }
  }

  /**
   * Get the item with the given id.
   * This async function will return the item from the cache right away
   * if it is already loaded, otherwise it will get it from the loader function.
   *
   * Any parallel loads to the same resource will be deduplicated.
   */
  @action.bound
  async get(id: string): Promise<Item> {
    const existing = this.map.get(id)
    if (existing) {
      return existing
    }

    let promise: Promise<Item>

    if (this.getValue) {
      promise = this.loader(id).then(result =>
        this.getValue!(result as LoaderResult),
      )
    } else {
      promise = this.loader(id) as Promise<Item>
    }

    this.map.set(id, promise)
    this.clearExcessItems()

    return promise
  }

  /**
   * Set the value of an item in the cache, overwriting any existing values.
   * The result of any ongoing load operation for this item will be ignored.
   */
  @action.bound
  set(id: string, item: Item) {
    this.map.set(id, Promise.resolve(item))
    this.clearExcessItems()
  }

  /** Clear all entries. */
  @action.bound
  reset() {
    this.map.clear()
  }

  /** The number of entries in the cache. */
  @computed
  get size() {
    return this.map.size
  }

  /**
   * Refresh state based on a state, updating the cache for any changes.
   *
   * The state can be a single item, an array of items or a
   * Paginated object, but each item must have an `id` property.
   * If the cache was constructed with a getValue, this will
   * be used to convert the items.
   *
   * Any new items will be added to the cache.
   *
   * Disappearing items will not be removed from the cache.
   */
  refresh(
    state:
      | WithId<LoaderResult>
      | WithId<LoaderResult>[]
      | Paginated<WithId<LoaderResult>, object>
      | null,
  ) {
    if (state instanceof Paginated) {
      state.list?.forEach(item => this.refresh(item))
    } else if (Array.isArray(state)) {
      state.forEach(item => this.refresh(item))
    } else if (state) {
      this.set(state.id, this.getValue ? this.getValue(state) : (state as Item))
    }
  }
}
