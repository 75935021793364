import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import {
  Artifact,
  ArtifactData,
  ArtifactFilters,
} from '../../interfaces/IArtifact'
import { ProjectContext } from '../../interfaces/IProject'
import { serverClient } from '../apiClients'

function url(ctx: ProjectContext, subPage?: string) {
  const base = `/projects/${ctx.project}/artifacts`
  return subPage ? `${base}/${subPage}` : base
}

export async function fetchArtifacts(
  ctx: ProjectContext,
  page?: number,
  perPage?: number,
  filters?: ArtifactFilters,
) {
  const response = await serverClient.get<PaginatedListResponse<Artifact>>(
    url(ctx),
    {
      params: { page, perPage, ...filters },
    },
  )

  return response.data
}

export async function fetchArtifact(ctx: ProjectContext, id: string) {
  const response = await serverClient.get<Response<Artifact>>(url(ctx, id))

  return response.data.data
}

export async function storeArtifact(ctx: ProjectContext, data: ArtifactData) {
  const response = await serverClient.post<Response<Artifact>>('artifacts', {
    projectId: ctx.project,
    ...data,
  })

  return response.data.data
}

export async function deleteArtifact(ctx: ProjectContext, id: string) {
  await serverClient.delete(url(ctx, id))
}
