import { useContext, createContext } from 'react'
import createStores, { AppStores } from '../shared/createStores'
import { ProjectContextStore } from '../stores/ProjectContextStore'
import { ProjectContext } from '../interfaces/IProject'

export const appStores = createStores()
const storesContext = createContext<AppStores>(appStores)

/**
 * Returns a global store.
 */
export function useStore<T extends keyof AppStores>(
  storeName: T,
): AppStores[T] {
  const storesHooks = useContext<AppStores>(storesContext)
  return storesHooks[storeName]
}

/** Get all global store hooks */
export function useStoreHooks() {
  const storesHooks = useContext<AppStores>(storesContext)

  return {
    /** Set the project context for all stores that rely on it */
    setProjectContext: (projectContext: ProjectContext | undefined) => {
      return Promise.all(
        Object.values(storesHooks).map(store => {
          if (store instanceof ProjectContextStore) {
            return store.setContext(projectContext)
          }
        }),
      )
    },
  }
}
