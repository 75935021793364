import { Card, CardProps } from 'antd'
import styled from 'styled-components'

export function Panel(props: CardProps) {
  return <CustomCard bordered={false} {...props} />
}

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: var(--ant-padding);
  }
  .ant-table {
    border: 1px solid var(--ant-color-border);
    box-shadow: none;
  }
  .ant-pagination {
    border: 1px solid var(--ant-color-border);
    border-top: none !important;
  }
`

export const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  font-size: 1.5em;
  padding-bottom: 16px;
`

export const PanelBody = styled.div``

export const PanelFooter = styled.div`
  padding-top: 16px;
`
