import { useAuth0 } from '@auth0/auth0-react'
import { Alert, Button, Result } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'
import { FullHeightCenterScreen } from '../../components/styled/FullHeightCenterScreen'
import { Panel } from '../../components/styled/Panel'
import { useAsPage } from '../../hooks/useAsPage'

interface NotAuthorizedProps {
  error: Error | string
  description?: string
  callback?: {
    label: string
    onClick: () => void
  }
}

const NotAuthorized: FC<NotAuthorizedProps> = ({
  error,
  description,
  callback,
}) => {
  useAsPage({
    title: 'Not Authorized',
  })
  const { isAuthenticated } = useAuth0()
  const extraOptions = (
    <ResultDetails>
      {description && <div>{description}</div>}
      <Alert message={`${error}`} type='error' showIcon />
      {callback && (
        <Button type='primary' onClick={callback.onClick}>
          {callback.label}
        </Button>
      )}
    </ResultDetails>
  )

  const result = (
    <ResultContainer>
      <Result
        status='403'
        title='403'
        subTitle='Sorry, you are not authorized to access this page.'
        extra={extraOptions}
      />
    </ResultContainer>
  )

  if (isAuthenticated) return <Panel>{result}</Panel>

  return <FullHeightCenterScreen>{result}</FullHeightCenterScreen>
}

export default NotAuthorized

const ResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #00000000;
`

const ResultDetails = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 20px;
`
