import { ListResponse, Response } from '../../interfaces/IApiResponses'
import {
  Pipeline,
  PipelineData,
  PipelineMetadata,
} from '../../interfaces/IPipeline'
import { ProjectContext } from '../../interfaces/IProject'
import { serverClient } from '../apiClients'
import {
  decodePipelineContent,
  encodePipelineContent,
} from '../pipelineEncoding'

function url({ org, project }: ProjectContext, subpath?: string) {
  const base = `/organizations/${org}/projects/${project}/pipelines`

  return subpath ? `${base}/${subpath}` : base
}

export async function fetchPipelines(ctx: ProjectContext) {
  const response = await serverClient.get<ListResponse<PipelineMetadata>>(
    url(ctx),
  )

  return response.data.list
}

export async function fetchPipeline(ctx: ProjectContext, pipelineId: string) {
  const response = await serverClient.get<Response<Pipeline>>(
    url(ctx, pipelineId),
  )

  response.data.data.pipeline = decodePipelineContent(
    response.data.data.pipeline,
  )

  return response.data.data
}

export async function createPipeline(ctx: ProjectContext, data: PipelineData) {
  const response = await serverClient.post<Response<Pipeline>>(url(ctx), {
    ...data,
    pipeline: encodePipelineContent(data.pipeline),
  })

  response.data.data.pipeline = decodePipelineContent(
    response.data.data.pipeline,
  )

  return response.data.data
}

export async function updatePipeline(
  ctx: ProjectContext,
  pipelineId: string,
  data: Partial<PipelineData>,
) {
  const response = await serverClient.put<Response<Pipeline>>(
    url(ctx, pipelineId),
    {
      ...data,
      pipeline: data.pipeline && encodePipelineContent(data.pipeline),
    },
  )

  response.data.data.pipeline = decodePipelineContent(
    response.data.data.pipeline,
  )

  return response.data.data
}

export async function deletePipeline(ctx: ProjectContext, pipelineId: string) {
  await serverClient.delete(url(ctx, pipelineId))
}
