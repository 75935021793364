import { PipelineData } from '../interfaces/IPipeline'

/** Encode pipeline content to base64  */
export function encodePipelineContent(content: string) {
  return btoa(content)
}

/** Decode pipeline content from base64  */
export function decodePipelineContent(content: string) {
  return atob(content)
}

export function encodePipelineSearchParams(pipeline: PipelineData) {
  return `?name=${encodeURIComponent(
    pipeline.name,
  )}&pipeline=${encodePipelineContent(pipeline.pipeline)}`
}

/**
 * Pull pipeline data from a URL generated with {@link encodePipelineSearchParams}.
 */
export function decodePipelineSearchParams(
  search: string,
): Partial<PipelineData> {
  const params = new URLSearchParams(search)
  const name = params.get('name') ?? undefined
  const pipeline = params.get('pipeline') ?? undefined
  return {
    name,
    pipeline: pipeline && decodePipelineContent(pipeline),
  }
}
