import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import { Release, ReleaseData, ReleaseFilters } from '../../interfaces/IRelease'
import { projectServerClient } from '../apiClients'

export async function fetchReleases(
  page?: number,
  perPage?: number,
  filters?: ReleaseFilters,
) {
  try {
    const response = await projectServerClient.get<
      PaginatedListResponse<Release>
    >(`/releases`, {
      params: { page, perPage, ...filters },
    })

    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchRelease(id: string) {
  try {
    const response = await projectServerClient.get<Response<Release>>(
      `/releases/${id}`,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function storeRelease(data: ReleaseData) {
  try {
    const dynamicMethod = data.id
      ? projectServerClient.put
      : projectServerClient.post
    const dynamicEndpoint = data.id ? `/releases/${data.id}` : `/releases`
    const response = await dynamicMethod<Response<Release>>(
      dynamicEndpoint,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteRelease(id: string) {
  try {
    await projectServerClient.delete(`/releases/${id}`)
  } catch (e) {
    throw e
  }
}
