import { observer } from 'mobx-react-lite'
import { FunctionComponent, useEffect, useState } from 'react'
import { useStore, useStoreHooks } from '../../hooks/useStore'
import {
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { LayoutLoader } from './LayoutLoader'
import { ProjectRouteParams } from '../../shared/paths'
import { PipelineBanner } from '../PipelineBanner'

export const ProjectLayout: FunctionComponent = observer(({ children }) => {
  const { pathname } = useLocation()
  const { replace } = useHistory()
  // We're in the project layout here, so the route match will be
  // scoped to the project's root path:
  const projectRoot = useRouteMatch<ProjectRouteParams>()

  const { loadProject, clearProject } = useStore('project')
  const { setProjectContext } = useStoreHooks()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // This component unmounts when we leave the /projects/:project route:
    return () => {
      clearProject()
      setProjectContext(undefined)
    }
  }, [])

  useEffect(() => {
    let isCancelled = false
    const loadProjectState = async () => {
      setIsLoading(true)

      const project = await loadProject(projectRoot.params.project).catch(
        () => undefined,
      )

      if (project) {
        if (project.organizationId !== projectRoot.params.org) {
          // We're in the wrong org somehow.
          // Stealthily switch to the correct org for this project by
          // regenerating a new version of the route to the project root
          // with the correct org, and use that to replace the pathname:
          const newProjectRoot = generatePath(projectRoot.path, {
            ...projectRoot.params,
            org: project.organizationId,
          })
          replace(pathname.replace(projectRoot.url, newProjectRoot))
        } else if (!isCancelled) {
          // Update stores:
          await setProjectContext(projectRoot.params)
        }
      }

      setIsLoading(false)
    }

    loadProjectState()

    return () => {
      // prevent async state updates on unmounted components:
      isCancelled = true
    }
  }, [projectRoot.params.project, projectRoot.params.org])

  return (
    <LayoutLoader id='project' isLoading={isLoading}>
      <PipelineBanner />
      {children}
    </LayoutLoader>
  )
})
