import { action, makeObservable, observable } from 'mobx'

export class LayoutStore {
  @observable backTarget: string | undefined = undefined
  @observable pageTitle = ''

  @observable loading: Record<string, string | boolean> = {}

  @observable isWizardVisible = false
  @observable isSimulatorOpen = false
  @observable isSimulatorReady = false

  @observable wasWizardSkipped: boolean = JSON.parse(
    localStorage.getItem('golioth.wizard.skipped') ?? 'false',
  )

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setBackTarget(path: string | undefined) {
    this.backTarget = path
  }

  @action.bound
  setSimulatorOpen(open: boolean) {
    this.isSimulatorOpen = open
  }

  @action.bound
  setSimulatorReady(ready: boolean) {
    this.isSimulatorReady = ready
  }

  @action.bound
  setPageTitle(title: string) {
    this.pageTitle = title
  }

  @action.bound
  setLoading(id: string, loading: string | boolean) {
    this.loading = { ...this.loading, [id]: loading }
  }

  @action.bound
  setWizardSkipped(skipped: boolean) {
    this.wasWizardSkipped = skipped

    if (skipped) return localStorage.setItem('golioth.wizard.skipped', 'true')
    localStorage.removeItem('golioth.wizard.skipped')
  }

  @action.bound
  setWizardVisible(visible: boolean) {
    this.isWizardVisible = visible
  }
}
