/**
 * Type-mutating filter that lets us drop all items that are undefined
 *
 * Usage:
 *
 *     [1, 2, undefined, 3].filter(notUndefined) -> number[]
 */
export function notUndefined<T>(item: T | undefined): item is T {
  return item !== undefined
}
