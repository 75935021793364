import { action, makeObservable, observable, runInAction } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { Secret, SecretCreate } from '../interfaces/ISecret'
import { ProjectContextStore } from './ProjectContextStore'
import {
  createSecret,
  deleteSecret,
  fetchSecrets,
} from '../shared/serverApi/secretsApi'

export class SecretStore extends ProjectContextStore {
  @observable pipelineSecrets: Secret[] | null = null
  @observable isLoading = false

  constructor() {
    super('secrets')
    makeObservable(this)
  }

  @action.bound
  protected onContextChange() {
    this.pipelineSecrets = null
  }

  @action.bound
  private setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  @action.bound
  async loadPipelineSecrets() {
    try {
      this.setIsLoading(true)

      const response = await fetchSecrets(this.context, 'PIPELINE')

      runInAction(() => {
        this.pipelineSecrets = response.list
      })
    } catch (e) {
      DefaultErrorNotification(e)
    } finally {
      this.setIsLoading(false)
    }
  }

  @action.bound
  async createSecret(secret: SecretCreate) {
    try {
      return await createSecret(this.context, secret)
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }

  @action.bound
  async deleteSecret(name: string) {
    try {
      await deleteSecret(this.context, name)
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }
}
