import { ThemeConfig } from 'antd'

export const goliothColors = {
  SiliconSunrise: '#f8b918',
  ConnectiveCoral: '#ff5760',
  PeriwinklePulse: '#5760ff',
  ByteBlue: '#578cff',
  DigitalDenim: '#1597ff',
  CircuitCeladon: '#73d13d',
  RebootRed: '#f5222d',
  BitstreamBlack: '#000000',
  GridGraphite: '#1a1a1a',
  SiliconShadow: '#232323',
  CyberCharcoal: '#353535',
  SpectrumSilver: '#f2f2f2',
  WiFiWhite: '#ffffff',
}

export const goliothLightTheme: ThemeConfig = {
  cssVar: {
    key: 'golioth-light',
  },
  token: {
    colorPrimary: goliothColors.PeriwinklePulse,
    colorLink: goliothColors.DigitalDenim,
    colorSuccess: goliothColors.CircuitCeladon,
    colorWarning: goliothColors.SiliconSunrise,
    colorError: goliothColors.RebootRed,
    colorHighlight: goliothColors.BitstreamBlack,
  },
  components: {
    Layout: {
      headerBg: goliothColors.WiFiWhite,
    },
  },
}
