import jsonexport from 'jsonexport'
import { action, autorun, makeObservable, observable, runInAction } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import {
  Artifact,
  ArtifactData,
  ArtifactFilters,
} from '../interfaces/IArtifact'
import {
  deleteArtifact,
  fetchArtifact,
  fetchArtifacts,
  storeArtifact,
} from '../shared/serverApi/artifactApi'
import { AsyncCache } from './AsyncCache'
import { Paginated } from './Paginated'
import { ProjectContextStore } from './ProjectContextStore'

export class ArtifactStore extends ProjectContextStore {
  @observable isSelectLoading = false

  @observable artifacts = new Paginated<Artifact, ArtifactFilters>(
    request =>
      fetchArtifacts(
        this.context,
        request.page,
        request.perPage,
        request.filters,
      ),
    {
      blueprintId: undefined,
      package: undefined,
      version: undefined,
    },
    { initialPageSize: 100, canChangePageSize: true },
  )
  @observable selectArtifacts: Artifact[] = []

  @observable allArtifacts = new AsyncCache(id =>
    fetchArtifact(this.context, id),
  )

  constructor() {
    super('artifacts')

    makeObservable(this)

    autorun(() => this.allArtifacts.refresh(this.artifacts))
    autorun(() => this.allArtifacts.refresh(this.selectArtifacts))
  }

  @action.bound
  protected onContextChange(): void | Promise<void> {
    this.selectArtifacts = []
    this.allArtifacts.reset()
    this.artifacts.reset()
  }

  @action.bound
  async saveArtifact(data: ArtifactData) {
    try {
      const artifact = await storeArtifact(this.context, data)

      DefaultSuccessMessage('Artifact', 'created', {
        name: `${data.package}@${data.version}`,
      })

      return artifact
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }

  @action.bound
  async bulkRemoveArtifacts(ids: string[]) {
    try {
      const result = await Promise.allSettled(
        ids.map(id => deleteArtifact(this.context, id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      successAmount &&
        DefaultSuccessMessage(`${successAmount} Artifact(s)`, 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }

  @action.bound
  async exportArtifacts() {
    try {
      const response = await fetchArtifacts(this.context)
      const csv = await jsonexport(response.list)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'artifacts.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
      DefaultErrorNotification(e)
    }
  }

  @action.bound
  async loadSelectArtifacts(filters?: ArtifactFilters) {
    try {
      this.isSelectLoading = true
      const response = await fetchArtifacts(this.context, 0, 250, filters)

      runInAction(() => {
        this.selectArtifacts = response.list
      })

      return response.list
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isSelectLoading = false
    }
  }
}
