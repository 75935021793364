import {
  uniqueNamesGenerator,
  Config,
  adjectives,
  colors,
  animals,
} from 'unique-names-generator'
import { blackList } from './randomNameBlackList'

const defaultConfig: Config = {
  dictionaries: [colors, adjectives, animals],
  separator: ' ',
  length: 3,
  style: 'capital',
}

const hasBlacklistedWord = (randomizedName: string, separator: string) => {
  const namesList = randomizedName.split(separator!)

  return !!namesList.filter(name => blackList.includes(name.toLowerCase()))
    .length
}

export const randomName = (config?: Partial<Config>): string => {
  const finalConfig = {
    ...defaultConfig,
    ...config,
  }

  let randomizedName

  do {
    randomizedName = uniqueNamesGenerator(finalConfig)
  } while (hasBlacklistedWord(randomizedName, finalConfig.separator!))

  return randomizedName
}
