import { PaginatedListResponse, Response } from '../../interfaces/IApiResponses'
import {
  ProjectCertificate,
  ProjectCertificateData,
} from '../../interfaces/IProjectCertificate'
import { projectServerClient } from '../apiClients'

export async function fetchProjectCertificates(
  page?: number,
  perPage?: number,
) {
  try {
    const response = await projectServerClient.get<
      PaginatedListResponse<ProjectCertificate>
    >(`/certificates`, {
      params: { page, perPage },
    })

    return response.data
  } catch (e) {
    throw e
  }
}

export async function fetchProjectCertificate(id: string) {
  try {
    const response = await projectServerClient.get<
      Response<ProjectCertificate>
    >(`/certificates/${id}`)

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function storeProjectCertificate(data: ProjectCertificateData) {
  try {
    const response = await projectServerClient.post<
      Response<ProjectCertificate>
    >('/certificates', data)

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteProjectCertificate(id: string) {
  try {
    await projectServerClient.delete(`/certificates/${id}`)
  } catch (e) {
    throw e
  }
}
