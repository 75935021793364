import { ListResponse, Response } from '../../interfaces/IApiResponses'
import { Project, ProjectData, ProjectFilters } from '../../interfaces/IProject'
import { serverClient } from '../apiClients'

export async function fetchProjects(filters?: ProjectFilters) {
  try {
    const response = await serverClient.get<ListResponse<Project>>('projects', {
      params: { ...filters },
    })

    return response.data.list
  } catch (e) {
    throw e
  }
}

export async function fetchProject(id: string) {
  try {
    const response = await serverClient.get<Response<Project>>(`projects/${id}`)

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function storeProject(data: ProjectData) {
  try {
    const dynamicMethod = data.id ? serverClient.put : serverClient.post
    const dynamicEndpoint = data.id ? `projects/${data.id}` : 'projects'
    const response = await dynamicMethod<Response<Project>>(
      dynamicEndpoint,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteProject(id: string) {
  try {
    await serverClient.delete(`projects/${id}`)
  } catch (e) {
    throw e
  }
}
