import { CheckoutItem } from '../../interfaces/IBilling'
import { serverClient } from '../apiClients'

export async function fetchCheckoutSession(
  orgId: string,
  items: CheckoutItem[],
  callbackPath: string,
) {
  try {
    const response = await serverClient.post<Record<'url', string>>(
      `/organizations/${orgId}/checkout_sessions`,
      {
        items,
        callbackPath,
      },
    )

    return response.data.url
  } catch (e) {
    throw e
  }
}

export async function fetchCustomerPortal(orgId: string, callbackPath: string) {
  try {
    const response = await serverClient.post<Record<'url', string>>(
      `/organizations/${orgId}/customer_portal_sessions`,
      {
        callbackPath,
      },
    )

    return response.data.url
  } catch (e) {
    throw e
  }
}
