import { LogoutOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Col, Dropdown, Layout, Row, Space } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { FunctionComponent } from 'react'
import { CannyChangelog } from '../CannyChangeLog'
import nameLogo from '../../assets/images/golioth-horizontal.svg'
import NavigationBreadcrumbs from './NavigationBreadcrumbs'
import { DocumentationLink } from '../DocumentationLink'
import { DeviceSimulator } from '../DeviceSimulator'

const { Header } = Layout

export const LayoutHeader: FunctionComponent = () => {
  const { logout, user } = useAuth0()
  const { xs, md } = useBreakpoint()

  const PopOverOptions = {
    items: [
      {
        key: 'email',
        label: user?.email,
        disabled: true,
      },
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: 'Logout',
        // Not using the useLogout hook here, as we don't want to go back to the same page:
        onClick: () => logout({ returnTo: window.location.origin }),
      },
    ],
  }

  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: 'auto',
        padding: '0px 20px',
        borderBottom: '2px solid #ff5760',
      }}
    >
      <Row
        gutter={[14, 14]}
        justify='space-between'
        align='middle'
        wrap={false}
      >
        {md && (
          <Row>
            <img src={nameLogo} style={{ height: 30, marginRight: 95 }} />
          </Row>
        )}
        <Col flex='auto'>
          <NavigationBreadcrumbs />
        </Col>
        <Col flex='none'>
          <Row gutter={14} align='middle' justify='space-between'>
            <Col>
              <DocumentationLink type='text' title=''>
                {md && 'Docs'}
              </DocumentationLink>
            </Col>
            <Col>
              <DeviceSimulator />
            </Col>
            <Col>
              <CannyChangelog />
            </Col>
            <Col>
              <Dropdown
                trigger={['click']}
                placement='bottomRight'
                menu={PopOverOptions}
              >
                <Space style={{ cursor: 'pointer' }} align='center'>
                  <Avatar
                    style={{ border: '1px solid #3add8f' }}
                    size='large'
                    src={user?.picture}
                  />
                  {!xs && (
                    <div
                      style={{
                        fontSize: 24,
                      }}
                    >
                      {user?.nickname}
                    </div>
                  )}
                </Space>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}
