import { message, Tooltip } from 'antd'
import Link from 'antd/lib/typography/Link'

type Method = 'created' | 'updated' | 'deleted'

interface ResourceExtra {
  name: string
  callback?: () => void
  callbackTooltip?: string
}

export const DefaultSuccessMessage = (
  resource: string,
  method: Method,
  resourceInfo?: ResourceExtra,
) => {
  const key = 'success'
  const { name, callback, callbackTooltip } = resourceInfo || {}

  const resourceText = callback ? (
    <Tooltip title={callbackTooltip}>
      <Link onClick={callback}>{name}</Link>
    </Tooltip>
  ) : (
    <b>{name}</b>
  )

  message.success({
    key,
    duration: 2.5,
    content: (
      <>
        {resource} {resourceText} {method} successfully
      </>
    ),
    onClick: () => message.destroy(key),
  })
}
