import { matchPath } from 'react-router-dom'
import { ExtractRouteParams, ExtractRouteOptionalParam } from 'react-router'
import { AppRoute } from '../routes'

type AppRouteParams<T extends AppRoute> = {
  [K in keyof ExtractRouteParams<T>]: K extends keyof ExtractRouteOptionalParam<T>
    ? string | undefined
    : string
}

export type OrgRouteParams = AppRouteParams<typeof paths.orgs.org>
export type ProjectRouteParams = AppRouteParams<typeof paths.projects.project>
export type OutputStreamRouteParams = AppRouteParams<
  typeof paths.projects.outputStreams.outputStream
>
export type DeviceRouteParams = AppRouteParams<
  typeof paths.projects.devices.device
>
export type BlueprintRouteParams = AppRouteParams<
  typeof paths.projects.blueprints.blueprint
>
export type ReleaseRouteParams = AppRouteParams<
  typeof paths.projects.releases.release
>
export type PipelineRouteParams = AppRouteParams<
  typeof paths.projects.pipelines.pipeline
>

function router<S extends string>(scope: S) {
  return <T extends string>(path: T) => `${scope}${path}` as `${S}${T}`
}

const org = router('/org/:org')
const project = router(org('/project/:project'))
const device = router(project('/device/:device'))
const outputStream = router(project('/settings/output-stream/:stream'))

interface RouteMap {
  [key: string]: AppRoute | RouteMap
}

export const paths = {
  index: '/',
  login: '/login',
  profilingQuestions: '/profiling-questions',
  orgs: {
    create: '/orgs/create',
    org: org(''),
    settings: org('/settings/:tab?'),
    edit: org('/edit'),
  },
  projects: {
    index: org('/projects'),
    create: org('/projects/create'),
    project: project(''),
    edit: project('/edit'),
    settings: project('/settings/:tab?'),
    tags: project('/tags'),
    logs: project('/logs'),
    stream: project('/lightdb-stream'),
    deviceSettings: project('/device-settings'),
    devices: {
      index: project('/devices'),
      create: project('/devices/create'),
      edit: device('/edit'),
      device: device('/:tab?'),
    },
    blueprints: {
      index: project('/blueprints'),
      blueprint: project('/blueprint/:blueprint'),
    },
    artifacts: {
      index: project('/artifacts'),
      create: project('/artifacts/create'),
    },
    releases: {
      index: project('/releases'),
      create: project('/releases/create'),
      release: project('/release/:release'),
    },
    apiKeys: project('/apikeys'),
    certificates: project('/certificates'),
    outputStreams: {
      outputStream: outputStream(''),
      index: project('/settings/output-streams'),
      edit: outputStream('/edit'),
      selectType: project('/settings/output-streams/select-type'),
      create: project('/settings/output-streams/:typeId/create'),
    },
    pipelines: {
      index: project('/pipelines'),
      pipeline: project('/pipeline/:pipeline'),
      create: project('/pipelines/create'),
    },
    secrets: project('/secrets'),
  },
  generic: {
    pipelines: {
      index: '/pipelines',
      create: '/pipeline',
    },
  },
  notFound: '*',
} as const

// Create an unused dummy function that will trigger a type error if
// any of the routes do not exist:
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _verifyRouteMapType = () => paths as RouteMap

export function getProjectRouteParams(pathname: string) {
  return (
    matchPath<ProjectRouteParams>(pathname, {
      path: paths.projects.project,
    })?.params ??
    matchPath<{ org: string; project: undefined }>(pathname, {
      path: paths.orgs.org,
    })?.params ?? { org: undefined, project: undefined }
  )
}
