export interface Permission {
  resource: Resources
  action: Actions
}

enum Actions {
  Read = 'read',
  Write = 'write',
}

// To update Resources use mapResourceRights at AccessStore.tsx
enum Resources {
  Apikeys = 'apikeys',
  Artifacts = 'artifacts',
  Blueprints = 'blueprints',
  Certificates = 'certificates',
  Credentials = 'credentials',
  Devices = 'devices',
  Devicestate = 'devicestate',
  Devicestream = 'devicestream',
  Integrations = 'integrations',
  Logs = 'logs',
  Organizations = 'organizations',
  Policies = 'policies',
  Projects = 'projects',
  Releases = 'releases',
  Settings = 'settings',
  Tags = 'tags',
}

export type Right = `${Resources}:${Actions}` | string

// export type ResourceRightsType = {
//   [key in Capitalize<Resources>]: {
//     [key in Capitalize<Actions>]?: Right
//   }
// }

// To update ResourceRights use mapResourceRights at AccessStore.tsx
const ResourceRights = {
  Credentials: {
    Read: 'credentials:read',
    Write: 'credentials:write',
  },
  Apikeys: {
    Read: 'apikeys:read',
    Write: 'apikeys:write',
  },
  Organizations: {
    Write: 'organizations:write',
    Read: 'organizations:read',
  },
  Integrations: {
    Write: 'integrations:write',
    Read: 'integrations:read',
  },
  Tags: {
    Write: 'tags:write',
    Read: 'tags:read',
  },
  Policies: {
    Write: 'policies:write',
    Read: 'policies:read',
  },
  Devicestate: {
    Write: 'devicestate:write',
    Read: 'devicestate:read',
  },
  Logs: {
    Read: 'logs:read',
  },
  Releases: {
    Write: 'releases:write',
    Read: 'releases:read',
  },
  Devicestream: {
    Read: 'devicestream:read',
  },
  Projects: {
    Write: 'projects:write',
    Read: 'projects:read',
  },
  Certificates: {
    Write: 'certificates:write',
    Read: 'certificates:read',
  },
  Devices: {
    Write: 'devices:write',
    Read: 'devices:read',
  },
  Artifacts: {
    Read: 'artifacts:read',
    Write: 'artifacts:write',
  },
  Settings: {
    Read: 'settings:read',
    Write: 'settings:write',
  },
  Blueprints: {
    Read: 'blueprints:read',
    Write: 'blueprints:write',
  },
  Pipelines: {
    Read: 'pipelines:read',
    Write: 'pipelines:write',
  },
  Secrets: {
    Read: 'secrets:read',
    Write: 'secrets:write',
  },
}

export const {
  Devices,
  Releases,
  Devicestate,
  Policies,
  Blueprints,
  Apikeys,
  Artifacts,
  Projects,
  Tags,
  Integrations,
  Devicestream,
  Credentials,
  Logs,
  Organizations,
  Settings,
  Certificates,
  Pipelines,
  Secrets,
} = ResourceRights
