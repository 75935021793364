import { ListResponse, Response } from '../../interfaces/IApiResponses'
import { Credential, CredentialData } from '../../interfaces/ICredential'
import { Device } from '../../interfaces/IDevice'
import { projectServerClient } from '../apiClients'

export async function fetchDeviceCredentials(deviceId: string) {
  try {
    const response = await projectServerClient.get<ListResponse<Credential>>(
      `/devices/${deviceId}/credentials`,
    )

    return response.data.list
  } catch (e) {
    throw e
  }
}

export async function fetchDevice(id: string) {
  try {
    const response = await projectServerClient.get<Response<Device>>(
      `/devices/${id}`,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function storeCredential(deviceId: string, data: CredentialData) {
  try {
    const response = await projectServerClient.post<Response<Credential>>(
      `/devices/${deviceId}/credentials`,
      data,
    )

    return response.data.data
  } catch (e) {
    throw e
  }
}

export async function deleteCredential(deviceId: string, credentialId: string) {
  try {
    await projectServerClient.delete(
      `/devices/${deviceId}/credentials/${credentialId}`,
    )
  } catch (e) {
    throw e
  }
}
