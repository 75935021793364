import jsonexport from 'jsonexport'
import { action, makeObservable, observable } from 'mobx'
import { DefaultErrorNotification } from '../components/DefaultErrorNotification'
import { DefaultSuccessMessage } from '../components/DefaultSuccessMessage'
import { Organization, OrganizationData } from '../interfaces/IOrganization'
import {
  deleteOrganization,
  fetchOrganization,
  fetchOrganizationQuotas,
  fetchOrganizations,
  storeOrganization,
} from '../shared/serverApi/organizationApi'
import { lazyObservable } from 'mobx-utils'

export class OrganizationStore {
  @observable isLoading = false
  @observable isBulkRemoving = false
  @observable isExporting = false

  /**
   * The current organization, or null if the user does not
   * have access to organization data.
   *
   * This is set by the organization layout, and should always have
   * a value if the user is on an organization page and has access to
   * view the organization.
   *
   * Note: Only organization owners have access to the organization data.
   */
  @observable organization: Organization | null = null

  organizations = lazyObservable<Organization[]>(sink =>
    this.loadOrganizations().then(organizations => sink(organizations)),
  )

  constructor() {
    makeObservable(this)
  }

  @action.bound
  async loadOrganizations() {
    try {
      this.isLoading = true
      return fetchOrganizations()
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  /** Sets the current organization */
  @action.bound
  async loadOrganization(id: string) {
    this.isLoading = true
    this.organization = await fetchOrganization(id).catch(() => null)
    this.isLoading = false
  }

  @action.bound
  async loadOrganizationQuotas(id: string) {
    try {
      this.isLoading = true
      const quotas = await fetchOrganizationQuotas(id)

      return quotas
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async saveOrganization(data: OrganizationData) {
    try {
      this.isLoading = true
      const organization = await storeOrganization(data)
      const action = data.id ? 'updated' : 'created'

      if (organization.id === this.organization?.id) {
        this.organization = organization
      }

      this.organizations.reset()

      DefaultSuccessMessage('Organization', action, {
        name: organization.name,
      })
      return organization
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async removeOrganization(id: string) {
    try {
      this.isLoading = true

      await deleteOrganization(id)
      this.organizations.reset()

      DefaultSuccessMessage('Organization', 'deleted')
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isLoading = false
    }
  }

  @action.bound
  async bulkRemoveOrganizations(ids: string[]) {
    try {
      this.isBulkRemoving = true
      const result = await Promise.allSettled(
        ids.map(id => deleteOrganization(id)),
      )
      const successAmount = result.filter(r => r.status === 'fulfilled').length
      result.map(
        r => r.status === 'rejected' && DefaultErrorNotification(r.reason),
      )

      if (successAmount) {
        this.organizations.reset()
        DefaultSuccessMessage(`${successAmount} Organization(s)`, 'deleted')
      }
    } catch (e) {
      DefaultErrorNotification(e)
      throw e
    } finally {
      this.isBulkRemoving = false
    }
  }

  @action.bound
  async exportOrganizations(organizations: Organization[]) {
    try {
      this.isExporting = true
      const csv = await jsonexport(organizations)
      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', csv])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'organizations.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
    } finally {
      this.isExporting = false
    }
  }
}
