type Features = 'device-settings' | 'device-rpc' | 'pipelines'

interface FlaggedFeature {
  name: Features
  enabled: boolean
}

export const features: FlaggedFeature[] = [
  {
    name: 'device-settings',
    enabled: getEnvFeature('settings'),
  },
  {
    name: 'device-rpc',
    enabled: getEnvFeature('rpc'),
  },
  {
    name: 'pipelines',
    enabled: getEnvFeature('pipelines'),
  },
]

export const isFeatureActive = (name: Features) => {
  const feature = features.find(f => f.name === name)
  return feature && feature.enabled
}

function getEnvFeature(name: string) {
  return process.env[`REACT_APP_FF_${name.toUpperCase()}_ENABLED`] === 'true'
}
