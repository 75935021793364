export const blackList = [
  'adverse',
  'afraid',
  'aggressive',
  'angry',
  'annoyed',
  'anxious',
  'armed',
  'arrogant',
  'awful',
  'awkward',
  'bad',
  'bizarre',
  'bloody',
  'broken',
  'burning',
  'cheerful',
  'compulsory',
  'controversial',
  'creepy',
  'criminal',
  'critical',
  'cruel',
  'damaged',
  'dead',
  'depressed',
  'desperate',
  'dirty',
  'disastrous',
  'disgusted',
  'disturbed',
  'disturbing',
  'drunk',
  'embarrassed',
  'embarrassing',
  'evil',
  'excited',
  'exciting',
  'explicit',
  'female',
  'feminist',
  'gay',
  'handicapped',
  'horrible',
  'hostile',
  'hot',
  'ill',
  'illegal',
  'inadequate',
  'inappropriate',
  'injured',
  'mental',
  'miserable',
  'misleading',
  'moaning',
  'naked',
  'nasty',
  'naughty',
  'nervous',
  'oral',
  'outrageous',
  'painful',
  'panicky',
  'poised',
  'poor',
  'pregnant',
  'racial',
  'repulsive',
  'ridiculous',
  'rude',
  'sexual',
  'socialist',
  'stupid',
  'toxic',
  'tragic',
  'ugliest',
  'ugly',
  'unhappy',
  'unnecessary',
  'unpleasant',
  'useless',
  'violent',
  'warm',
  'wasteful',
  'weak',
  'weird',
  'wet',
  'wicked',
  'worried',
  'worrying',
  'wrong',
  'xenophobic',
]
